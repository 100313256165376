import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_USERS_ACTION',
    'FETCH_USERS_REQUEST',
    'FETCH_USERS_SUCCESS',
    'FETCH_USERS_FAILURE',

    'FETCH_MORE_USERS_ACTION',
    'FETCH_MORE_USERS_REQUEST',
    'FETCH_MORE_USERS_SUCCESS',
    'FETCH_MORE_USERS_FAILURE',

    'ADD_USER_ACTION',
    'ADD_USER_REQUEST',
    'ADD_USER_SUCCESS',
    'ADD_USER_FAILURE',

    'EDIT_USER_ACTION',
    'EDIT_USER_REQUEST',
    'EDIT_USER_SUCCESS',
    'EDIT_USER_FAILURE',

    'FETCH_MATCHING_USER_ACTION',
    'FETCH_MATCHING_USER_REQUEST',
    'FETCH_MATCHING_USER_SUCCESS',
    'FETCH_MATCHING_USER_FAILURE',

    'DELETE_USER_ACTION',
    'DELETE_USER_REQUEST',
    'DELETE_USER_SUCCESS',
    'DELETE_USER_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
