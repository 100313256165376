import React from 'react';
import {takeLatest} from 'redux-saga/effects';
import {ActionTypes as ApplicantActionTypes} from 'modules/applicant';
import {ActionTypes as ReferrerActionTypes} from 'modules/referrer';
import {ActionTypes as CompanyActionTypes} from 'modules/companies';
import {ActionTypes as JobActionTypes} from 'modules/companyJobs';
import {ActionTypes as CustomerTypes} from 'modules/customer';
import {ActionTypes as UserActionTypes} from 'modules/users';
import {ActionTypes as NotifActionTypes} from 'modules/notifications';
import {toastr} from 'react-redux-toastr'
import Flex from 'components/Flex';
import { FormattedMessage } from 'react-intl';
import defaultMessages from 'i18n/defaultMessages';

export function showToastr(options, action) {
    toastr[options.type](undefined, undefined, {
        component: () => <Flex centerChildren style={{minHeight: 50}}><FormattedMessage {...options.message} /></Flex>
    });
};

export default [
    takeLatest([
        ApplicantActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS,
        ApplicantActionTypes.APPLICANTS_UPDATE_APPLICANT_SUCCESS,
        CompanyActionTypes.ADD_COMPANY_SUCCESS,
        CompanyActionTypes.EDIT_COMPANY_SUCCESS,
        JobActionTypes.CREATE_JOB_SUCCESS,
        JobActionTypes.EDIT_JOB_SUCCESS,
        JobActionTypes.JOB_PUSH_REQUEST_SUCCESS,
        ReferrerActionTypes.GENERATE_LINK_SUCCESS,
        ReferrerActionTypes.CREATE_REFERRER_SUCCESS,
        ReferrerActionTypes.REFERRER_REVOKE_NOTIF_SUCCESS,
        UserActionTypes.ADD_USER_SUCCESS,
        UserActionTypes.EDIT_USER_SUCCESS,
        UserActionTypes.DELETE_USER_SUCCESS,
        CustomerTypes.EDIT_CUSTOMER_SETTINGS_SUCCESS,
        NotifActionTypes.CREATE_JOB_NOTIF_SUCCESS,
    ], showToastr, {message: defaultMessages.operation_success, type: 'success'}),
    takeLatest([
        ApplicantActionTypes.SUBMIT_APPLICATION_FORM_FAILURE,
        ApplicantActionTypes.APPLICANTS_UPDATE_APPLICANT_FAILURE,
        CompanyActionTypes.ADD_COMPANY_FAILURE,
        CompanyActionTypes.EDIT_COMPANY_FAILURE,
        JobActionTypes.CREATE_JOB_FAILURE,
        JobActionTypes.EDIT_JOB_FAILURE,
        JobActionTypes.JOB_PUSH_REQUEST_FAILURE,
        ReferrerActionTypes.GENERATE_LINK_FAILURE,
        ReferrerActionTypes.CREATE_REFERRER_FAILURE,
        ReferrerActionTypes.REFERRER_REVOKE_NOTIF_FAILURE,
        UserActionTypes.ADD_USER_FAILURE,
        UserActionTypes.EDIT_USER_FAILURE,
        UserActionTypes.DELETE_USER_FAILURE,
        CustomerTypes.EDIT_CUSTOMER_SETTINGS_FAILURE,
        NotifActionTypes.CREATE_JOB_NOTIF_FAILURE,
    ], showToastr, {message: defaultMessages.operation_failure, type: 'error'}),
];
