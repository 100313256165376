import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'ADD_COMPANY_REQUEST',
    'ADD_COMPANY_SUCCESS',
    'ADD_COMPANY_FAILURE',
    'ADD_COMPANY_ACTION',

    'EDIT_COMPANY_REQUEST',
    'EDIT_COMPANY_SUCCESS',
    'EDIT_COMPANY_FAILURE',
    'EDIT_COMPANY_ACTION',

    'FETCH_COMPANIES_REQUEST',
    'FETCH_COMPANIES_SUCCESS',
    'FETCH_COMPANIES_FAILURE',

    'FETCH_COMPANY_TERMS_REQUEST',
    'FETCH_COMPANY_TERMS_SUCCESS',
    'FETCH_COMPANY_TERMS_FAILURE',

    'FETCH_MATCHING_COMPANY_REQUEST',
    'FETCH_MATCHING_COMPANY_SUCCESS',
    'FETCH_MATCHING_COMPANY_FAILURE',

    'FETCH_COMPANY_TERMS_ACTION',
    'FETCH_COMPANY_TERMS_REQUEST',
    'FETCH_COMPANY_TERMS_SUCCESS',
    'FETCH_COMPANY_TERMS_FAILURE',

    'EDIT_COMPANY_MOUNTING',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
