import {createSelector} from "reselect";
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Selectors as NavigationSelectors} from 'modules/navigation';

export const sliceSelector = state => state.users;
export const usersSelector = createSelector(sliceSelector, slice => slice.users || []);
export const fetchingSelector = createSelector(sliceSelector, slice => !!slice.fetching);
export const loadingSelector = createSelector(sliceSelector, slice => !!slice.loading);
export const lastUserSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreUsersSelector = createSelector(sliceSelector, slice => !!slice.hasMore);

export const enhancedUsersSelector = createSelector(usersSelector, CompaniesSelectors.companiesSelector,
    (users, companies) => {
        return users.map(a => {
            const company = companies.find(c => c.id === a.companyId);
            if (!company) {
                return a;
            }
            return {...a, companyName: company.name};
        })
    }
);

export const matchingUserIdSelector = createSelector(
    NavigationSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/users\/([^/?]*)/) || [];
        const uid = matchedRegex[1];
        return uid;
    }
);

export const matchingUserSelector = createSelector(
    matchingUserIdSelector, usersSelector,
(uid, companies) => {
        return companies.find(c => c.uid === uid);
    }
);
