import {createSelector} from 'reselect';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as NavigationSelectors} from 'modules/navigation';

export const sliceSelector = state => state.applicant;
export const applicantFormSelector = createSelector(sliceSelector, slice => slice.applicant || {});
export const applicantsSelector = createSelector(sliceSelector, slice => slice.applicants || []);
export const isLoadingSelector = createSelector(sliceSelector, slice => !!slice.loading);
export const isLoadingFileSelector = createSelector(sliceSelector, slice => slice.loadingFile);
export const isFetchingSelector = createSelector(sliceSelector, slice => !!slice.fetching);
export const applicantsFilterSelector = createSelector(sliceSelector, slice => slice.filter || {});
export const lastApplicantSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreApplicantsSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const appliedJobsSelector = createSelector(sliceSelector, slice => slice.appliedJobs || new Set());
export const wasApplicantJustCreatedSelector = createSelector(sliceSelector, slice => slice.justCreated);

export const enhancedApplicantsSelector = createSelector(
    applicantsSelector,
    CompaniesSelectors.companiesSelector,
    JobsSelectors.companyJobsSelector,
    ReferrerSelectors.referrersSelector,
    (applicants, companies, jobs, referrers) => {
        return applicants.map(a => {
            const company = companies.find(c => c.id === a.companyId);
            const job = jobs.find(j => j.id === a.jobId);
            const referrer = referrers.find(j => j.id === a.referrerId);
            if (!company && !job && !referrer) {
                return a;
            }
            return {
                ...a,
                companyName: company && company.name,
                jobTitle: job && job.title,
                referrerName: referrer && referrer.name,
            };
        })
    }
);

export const matchingApplicantIdSelector = createSelector(
    NavigationSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/applicants\/([^/?]*)/) || [];
        const applicantId = matchedRegex[1];
        return applicantId;
    }
);

export const matchingApplicantSelector = createSelector(
    matchingApplicantIdSelector, applicantsSelector,
(matchingApplicantId, applicants) => {
        return applicants.find(c => c.id === matchingApplicantId);
    }
);

export const matchingApplicantDetailsSelector = createSelector(
    matchingApplicantIdSelector,
    applicantsSelector,
    CompaniesSelectors.companiesSelector,
    JobsSelectors.companyJobsSelector,
(matchingApplicantId, applicants, companies, jobs) => {
        const applicant = applicants.find(c => c.id === matchingApplicantId);
        if (!applicant) {
            return applicant;
        }
        const company = companies.find(c => c.id === applicant.companyId);
        const job = jobs.find(c => c.id === applicant.jobId);
        if (!company && !job) {
            return applicant;
        }
        return {
            ...applicant,
            companyName: company && company.name,
            companyLogo: company && company.logo,
            jobTitle: job && job.title
        };
    }
);

export const jobAppliedSelector = createSelector(JobsSelectors.matchingJobIdSelector, appliedJobsSelector, 
    (matchingJobId, appliedJobs) => {
        if (!matchingJobId) {
            return false;
        }
        return appliedJobs.has(matchingJobId);
    },
)
