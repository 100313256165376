import {ActionTypes} from './sharesActions';
import config from 'config';

const appendShares = (state, sharesToMerge) => {
    const shares = (state.shares || []).concat(sharesToMerge || []);
    return shares;
}

export default (state = {}, action) => {
    let nextState;
    const {type, payload = {}} = action;
    switch (type) {
    case ActionTypes.FETCH_SHARES_REQUEST:
        nextState = {...state, loadingShares: true, shares: null, queryData: payload};
        break;
    case ActionTypes.FETCH_SHARES_SUCCESS: {
        const {shares = [], last} = payload;
        nextState = {
            ...state,
            loadingShares: false,
            shares: shares,
            last,
            hasMore: shares.length === config.sharesPagination,
        };
        break;
    }
    case ActionTypes.FETCH_MORE_SHARES_SUCCESS: {
        const {shares = [], last} = payload;
        nextState = {
            ...state,
            loadingShares: false,
            shares: appendShares(state, shares),
            last,
            hasMore: shares.length === config.sharesPagination,
        };
        break;
    }
    case ActionTypes.FETCH_SHARES_FAILURE:
        nextState = {...state, loadingShares: false, shares: null};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
