import {ActionTypes} from './usersActions';
import config from 'config';

const mergeUsers = (state, user) => {
    const users = (state.users || []).slice();
    const position = users.findIndex(c => c.uid === user.uid);
    const newUserPosition = position >= 0 ? position : users.length;
    users[newUserPosition] = user;
    return users;
}

const appendUsers = (state, usersToMerge) => {
    const users = (state.users || []).concat(usersToMerge || []);
    return users;
}

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.FETCH_USERS_REQUEST:
    case ActionTypes.FETCH_MATCHING_USER_REQUEST:
        nextState = {...state, fetching: true};
        break;
    case ActionTypes.FETCH_USERS_SUCCESS:{
        const {users = [], last} = payload;
        nextState = {
            ...state,
            users, last,
            hasMore: users.length === config.usersPagination,
            fetching: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_USERS_SUCCESS: {
        const {users = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            users: appendUsers(state, users),
            last,
            hasMore: users.length === config.usersPagination,
        };
        break;
    }
    case ActionTypes.FETCH_USERS_FAILURE:
    case ActionTypes.FETCH_MATCHING_USER_FAILURE:
        nextState = {...state, fetching: false};
        break;
    case ActionTypes.FETCH_MATCHING_USER_SUCCESS: 
        nextState = {...state, fetching: false, users: mergeUsers(state, payload)};
        break;
    case ActionTypes.EDIT_USER_REQUEST:
    case ActionTypes.ADD_USER_REQUEST:
    case ActionTypes.DELETE_USER_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.EDIT_USER_SUCCESS:
    case ActionTypes.ADD_USER_SUCCESS:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.EDIT_USER_FAILURE:
    case ActionTypes.ADD_USER_FAILURE:
    case ActionTypes.DELETE_USER_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.DELETE_USER_SUCCESS:
        nextState = {...state, loading: false, users: state.users.filter(u => u.uid !== payload)};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
