import React, {Component} from 'react';
import ReactLoader from 'react-loaders';
import classnames from 'classnames';

export default class Loader extends Component {
    render = () => {
        const {children, loading, inline, style, fixed} = this.props;
        if (!loading) {
            return children || null;
        }
        const classes = classnames('loader', {'loader--inline': inline, 'loader--fixed': fixed});
        return (
            <div className={classes} style={style}>
                <ReactLoader type="ball-clip-rotate-multiple" active className="loader__animation" />
            </div>
        );
    }
}
