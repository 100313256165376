import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoader from 'react-loaders';
import {Selectors as I18nSelectors} from 'modules/i18n';
import defaultMessages from 'i18n/defaultMessages';
import { FormattedMessage } from 'react-intl';

class SimpleTable extends Component {
    static defaultProps = {
        loadMore: () => {},
        getTrProps: () => ({}),
    }

    getColumnHeader = ({title, id, accessor, ...rest}) => {
        return {
            Header: title,
            // headerClassName: `header--${name}`,
            id,
            accessor: accessor || id,
            ...rest,
        };
    }

    getTrProps = (onRowClick) => {
        const {getTrProps} = this.props;
        if (!onRowClick) {
            return getTrProps;
        }
        return (...args) => ({
            onClick: () => onRowClick(args[1].original),
            ...getTrProps(...args),
        });
    }

    getColumns = () => {
        const {columns, showDelete} = this.props;
        const tableColumns = columns.map(this.getColumnHeader);
        if (showDelete) {
            tableColumns.push(this.getColumnHeader({
                title: <FormattedMessage {...defaultMessages.general_delete} />,
                id: 'del',
                accessor: this.deleteColumn,
                maxWidth: 100,
            }))
        }
        return tableColumns;
    }

    handleDeleteClick = (e, user) => {
        e.stopPropagation();
        this.props.onRowDelete(user);
    }

    deleteColumn = (user) => {
        return (
            <button className="ReactTable__deleteButton" onClick={e => this.handleDeleteClick(e, user)}>
                <i className="far fa-trash-alt" />
            </button>
        );
    }

    render = () => {
        const {columns, showDelete, onRowClick, className, hasMore, loadMore, useWindow, rtl, ...rest} = this.props;
        const tableColumns = this.getColumns();
        const trProps = this.getTrProps(onRowClick);
        const classes = classnames('-striped -highlight', className, {'ReactTable--clickable': trProps, rtl});
        return (
            <InfiniteScroll
                initialLoad={false}
                hasMore={hasMore}
                loadMore={loadMore}
                useWindow={useWindow}
                loader={<ReactLoader type="ball-beat" active key="dummy" />}
            >
                <ReactTable
                        multiSort={false}
                        sortable={false}
                        {...rest}
                        getTrProps={trProps}
                        getTableProps={() => ({ style: { display: 'block' } })} // direction: rtl fix
                        minRows={0}
                        className={classes}
                        columns={tableColumns}
                        data={this.props.rows}
                        showPagination={false}
                        pageSize={9999999}
                        loading={false}
                        onSortedChange={this.props.onSortedChange}
                        manual
                        noDataText=""
                />
            </InfiniteScroll>
        );
    }
}

export default connect(state => ({rtl: I18nSelectors.isRtlSelector(state)}))(SimpleTable);
