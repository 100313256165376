import axios from 'axios';
import firebase from './firebase';

const baseURL = `${window.location.origin}/app`;

const axiosInstance = axios.create({baseURL});

axiosInstance.interceptors.request.use(config => {
    if (!firebase.auth().currentUser) {
        return config;
    }
    return firebase.auth().currentUser.getIdToken().then(token => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });
});
export default axiosInstance;
