import axios from 'services/axios';
import firebase from 'services/firebase';
import {delay, eventChannel} from 'redux-saga';
import {put, call, takeLatest, take, select, fork} from 'redux-saga/effects';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Actions, Selectors} from 'modules/notifications';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as JobSelectors} from 'modules/companyJobs';
import {ActionTypes as NotifSettingsActionTypes} from 'containers/notificationsSettingsPage/notifsSettingsActions';
import {ActionTypes as CreateNotifModalAT} from 'containers/createNotifModal/createNotifModalActions';
import {ActionTypes as EnableNotifModalAT} from 'containers/enableNotifModal/enableNotifModalActions';
import {ActionTypes as NotifHistoryAT} from 'containers/notifHistoryPage/notifHistoryPageActions';
import config from 'config';
import _ from 'lodash';

function tokenRefreshEmitter() {
    return eventChannel(emitter => {
        return firebase.messaging().onTokenRefresh(async () => {
            emitter();
        })
    });
}

async function resetNotificationToken() {
    try {
        const registrations = await navigator.serviceWorker.getRegistrations()
        await Promise.all(registrations.map(r => r.unregister()));
        const lastReload = parseInt(sessionStorage.getItem('lnr'));
        if (!lastReload || (lastReload + config.notif.reloadThrottleSeconds * 1000) < Date.now()) {
            sessionStorage.setItem('lnr', Date.now())
            window.location.reload();
        } else {
            console.warn('Reloading prevented due to reloadThrottle')
        }
    } catch (err) {
        console.error(err);
    }
}
function* registerNotifToken() {
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const referrerId = yield select(ReferrerSelectors.referrerIdSelector);
        const token = yield firebase.messaging().getToken();
        yield call(axios.post, `companies/${companyId}/tokens`, {referrerId, token});
        localStorage.setItem(config.notif.sentToServerKey, Date.now());
    } catch (err) {
        console.error(err);
        const code = _.get(err, 'response.data.key', err.code);
        if (['messaging/token-unsubscribe-failed', 'invalidToken'].includes(code)) {
            yield call(resetNotificationToken);
        }
    }
}

function* tokenRefreshHandler() {
    if (!firebase.messaging.isSupported()) {
        return;
    }
    const chan = yield call(tokenRefreshEmitter);
    try {
        while (true) {
            yield take(chan);
            yield fork(registerNotifToken);
        }
    } catch (err) {
        console.error(err);
    }
}

function* handleGrantSequence() {
    const permissionGranted = yield select(Selectors.permissionGranted);
    if (permissionGranted) {
        const lastSend = localStorage.getItem(config.notif.sentToServerKey);
        const isSendTimeout = (parseInt(lastSend) + config.notif.tokenResendTimeout * 24 * 60 * 60 * 1000 < Date.now());
        if (!lastSend || isSendTimeout) {
            yield fork(registerNotifToken);
        } else {
            yield firebase.messaging().getToken(); // downloads SW
        }
        return;
    }
    
    const canGetPermission = yield select(Selectors.permissionCanBeGranted);
    const isNotifEnabled = yield select(CompaniesSelectors.publicNotifEnabledSelector);
    const lastDeny = localStorage.getItem(config.notif.lastReqKey);

    const isDenyTimeoutPassed = !lastDeny || (parseInt(lastDeny) + config.notif.denyTimeout < Date.now());
    if (!isNotifEnabled || !canGetPermission || !isDenyTimeoutPassed) {
        return;
    }
    yield delay(config.notif.permissionDelay);
    yield put(Actions.SHOW_NOTIF_PERMISSION_MODAL());
}

function* showApprovalRequest() {
    yield put(Actions.HIDE_NOTIF_PERMISSION_MODAL());
    const result = yield Notification.requestPermission();
    yield put(Actions.NOTIF_PERMISSION_CHANGED({state: result}));
    if (result === 'granted') {
        yield call(registerNotifToken);
    }
}

function* handleDenyRequest() {
    yield put(Actions.HIDE_NOTIF_PERMISSION_MODAL());
    localStorage.setItem(config.notif.lastReqKey, Date.now());
}

function* createNewJobNotif(action) {
    yield put(Actions.CREATE_JOB_NOTIF_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const jobId = yield select(JobSelectors.matchingJobIdSelector);

        const payload = {...action.payload, jobId};
        yield call(axios.post, `customers/me/companies/${companyId}/notifications/newJob`, payload);
        yield put(Actions.CREATE_JOB_NOTIF_SUCCESS());
    } catch (err) {
        yield put(Actions.CREATE_JOB_NOTIF_FAILURE(err));
    }
}

function* updateSettings(action) {
    yield put(Actions.UPDATE_NOTIFS_SETTINGS_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);

        const res = yield call(axios.patch, `customers/me/companies/${companyId}/notifications/settings`, action.payload);
        yield put(Actions.UPDATE_NOTIFS_SETTINGS_SUCCESS({data: res.data, companyId}));
    } catch (err) {
        yield put(Actions.UPDATE_NOTIFS_SETTINGS_FAILURE(err));
    }
}

function getNotificationSettings(companyId) {
    return firebase.firestore().collection('companies').doc(companyId)
                .collection('settings-private').doc('notifs').get();
}

function* fetchNotificationSettings() {
    try {
        yield put(Actions.FETCH_NOTIFS_SETTINGS_REQUEST());
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const doc = yield call(getNotificationSettings, companyId);
        yield put(Actions.FETCH_NOTIFS_SETTINGS_SUCCESS({settings: doc.data()}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_NOTIFS_SETTINGS_FAILURE(err));
    }
}

const fetchNotifHistoryLogic = async (companyId, startAfter) => {
    let queryBuilder = firebase.firestore()
    .collection('companies')
    .doc(companyId)
    .collection('notifs-private')
    .orderBy('created', 'desc')
    .limit(config.notifsPagination);

    if (startAfter) {
        queryBuilder = queryBuilder.startAfter(startAfter);
    }
    const query = await queryBuilder.get();
    const last = query.docs[query.docs.length - 1];
    const history = query.docs.map(d => ({...d.data(), id: d.id}));
    return {history, last};
}

function* fetchNotifHistory() {
    yield put(Actions.FETCH_NOTIF_HISTORY_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {history, last} = yield call(fetchNotifHistoryLogic, companyId);
        yield put(Actions.FETCH_NOTIF_HISTORY_SUCCESS({history, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_NOTIF_HISTORY_FAILURE(err));
    }
}

function* fetchMoreNotifHistory() {
    yield put(Actions.FETCH_MORE_NOTIF_HISTORY_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const lastSnap = yield select(Selectors.lastNotifHistorySelector);
        const {history, last} = yield call(fetchNotifHistoryLogic, companyId, lastSnap);
        yield put(Actions.FETCH_MORE_NOTIF_HISTORY_SUCCESS({history, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_NOTIF_HISTORY_FAILURE(err));
    }
}



export default [
    takeLatest(NotifSettingsActionTypes.NOTIFS_SETTINGS_PAGE_LOAD, fetchNotificationSettings),
    takeLatest(NotifSettingsActionTypes.NOTIFS_SETTINGS_PAGE_SUBMIT, updateSettings),
    takeLatest(CreateNotifModalAT.CREATE_NOTIF_MODAL_MOUNT, fetchNotificationSettings),
    takeLatest(CreateNotifModalAT.CREATE_NOTIF_MODAL_SUBMIT, createNewJobNotif),
    takeLatest(EnableNotifModalAT.ENABLE_NOTIF_MODAL_APPROVE, showApprovalRequest),
    takeLatest(EnableNotifModalAT.ENABLE_NOTIF_MODAL_DENY, handleDenyRequest),
    takeLatest(EnableNotifModalAT.ENABLE_NOTIF_MODAL_MOUNT, handleGrantSequence),
    takeLatest(NotifHistoryAT.FETCH_NOTIFS_HISTORY, fetchNotifHistory),
    takeLatest(NotifHistoryAT.FETCH_MORE_NOTIFS_HISTORY, fetchMoreNotifHistory),
]

export function* watchers() {
    yield fork(tokenRefreshHandler);
}