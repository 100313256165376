export default {
    'general_add': 'הוסף',
    'general_cancel': 'בטל',
    'general_delete': 'מחק',
    'general_close': 'סגור',
    'general_save': 'שמור',
    'general_true': 'כן',
    'general_false': 'לא',
    'general_submit': 'שלח',
    'general_filter': 'סנן',
    'general_yes': 'כן',
    'general_no': 'לא',
    'general_approve': 'אישור',
    'general_nextTime': 'אולי בפעם אחרת',

    'clickToCopy_copy': 'העתק',
    'clickToCopy_copied': 'הועתק',
    'clickToCopy_not_copied': 'שגיאה, לא הועתק',

    'loading': 'טוען...',
    'login_header': 'טופס התחברות',
    'login_userLabel': 'שם משתמש',
    'login_passwordLabel': 'סיסמה',
    'login_mismatch': 'שם משתמש וסיסמה אינם תואמים',
    'login_failed': 'אופס, קרתה שגיאה',
    'login': 'התחבר',
    'login_joinUs': 'עדיין אין לכם לינקאצ\'?',
    'login_joinUsLink': 'ליצירת קשר ופרטים נוספים',
    'access_unauthorizedToView': 'אין לך גישה לדף זה',

    'company_id': 'מזהה חברה (אנגלית)',
    'company_locale': 'שפת ממשק חברה',
    'company_name': 'שם החברה',
    'company_logo': 'לוגו',
    'company_cover': 'באנר',
    'company_coverMobile': 'באנר למובייל',
    'company_website': 'אתר החברה',
    'company_about': 'קצת עלינו',
    'company_terms': 'תנאי שימוש',
    'company_location': 'מטה החברה',
    'company_contactName': 'שם איש קשר (לשימוש פנימי)',
    'company_contactPhone': 'טלפון איש קשר (לשימוש פנימי)',
    'company_contactEmail': 'אימייל איש קשר (לשימוש פנימי)',
    'company_leadEmails': 'אימיילים למשלוח לידים',
    'company_rIncentive': 'משפט תמריץ לעובד ממליץ',
    'company_aIncentive': 'משפט תמריץ למועמד',
    'company_referrerWorthTitle': 'כותרת',
    'company_referrerWorth': 'למה זה כדאי לי',
    'company_numEmployees': 'מספר עובדים',
    'company_cover_description': 'גרור לכאן באנר, או לחץ כדי לבחור (jpg, png, mp4)',
    'company_coverMobile_description': 'גרור לכאן באנר למכשירי מובייל, או לחץ כדי לבחור (jpg, png, mp4)',
    'company_logo_description': 'גרור לכאן את לוגו החברה, או לחץ כדי לבחור (jpg, png)',
    'company_newApplicantEmailTitle': 'כותרת מייל למועמד חדש',
    company_isCVPassThrough: 'דלג על שמירת קורות החיים',

    'company_referrerMeta': 'תצוגה מקדימה בשיתוף הלינק - עובד ממליץ',
    'company_applicantMeta': 'תצוגה מקדימה בשיתוף הלינק - מועמד',
    'company_referrerMetaTitle': 'כותרת',
    'company_referrerMetaDescription': 'תאור',
    'company_referrerMetaImg': 'תמונה',
    'company_referrerMetaImg_description': 'גרור לכאן תמונה (jpg, png)',
    'company_applicantMetaTitle': 'כותרת',
    'company_applicantMetaDescription': 'תאור',
    'company_applicantMetaImg': 'תמונה',
    'company_applicantMetaImg_description': 'גרור לכאן תמונה (jpg, png)',

    'company_preview_company': 'צפה במשרות',
    'company_site_title': 'האתר שלנו',
    'job_links_title': 'שתפ/י משרה',

    'create_job_submit': 'צור משרה',
    'edit_job_submit': 'שמור משרה',
    create_job_submit_and_notify: 'צור ושלח התראה',

    'job_id': 'מזהה משרה',
    'job_shareUrlLink': 'לינק להפצה לכל העובדים',
    'job_title': 'כותרת משרה',
    'job_description': 'תיאור המשרה',
    'job_internalId': 'מספר משרה (לזיהוי פנימי, לא חובה)',
    'job_image': 'תמונה',
    'job_requirements': 'דרישות',
    'job_type': 'סוג משרה',
    'job_status': 'היקף משרה',
    'job_location': 'מיקום',
    'job_posted': 'נוצרה',
    'job_salary': 'משכורת',
    'job_leadEmails': 'אימיילים למשלוח לידים',    
    'job_contactName': 'שם איש קשר (לשימוש פנימי)',
    'job_contactPhone': 'טלפון איש קשר (לשימוש פנימי)',
    'job_contactEmail': 'אימייל איש קשר (לשימוש פנימי)',
    'job_active': 'המשרה פעילה',
    'job_inactive': 'המשרה איננה פעילה',
    'job_rIncentive': 'משפט תמריץ לעובד ממליץ',
    'job_aIncentive': 'משפט תמריץ למועמד',
    'job_referrerWorthTitle': 'כותרת',
    'job_referrerWorth': 'למה זה כדאי לי',
    'job_cover': 'באנר משרה',
    'job_coverMobile': 'באנר משרה למובייל',
    'job_order': 'עדיפות',
    'job_innerRecruiter': 'מגייס/ת',
    'job_innerRecruiterManager': 'מנהל גיוס',
    'job_section': 'מחלקה',

    'job_innerFields_title': 'שדות לשימוש פנימי',

    'job_recruiter_title': 'פרטי המגייס',
    'job_recruiterImg': 'תמונת המגייס',
    'job_recruiterName': 'שם המגייס',
    'job_recruiterHeadline': 'כותרת ב3 מילים',
    'job_recruiterDescription': 'תיאור קצר',

    'job_referrerMeta': 'תצוגה מקדימה בשיתוף הלינק - עובד ממליץ',
    'job_applicantMeta': 'תצוגה מקדימה בשיתוף הלינק - מועמד',
    'job_referrerMetaTitle': 'כותרת',
    'job_referrerMetaDescription': 'תאור',
    'job_referrerMetaImg': 'תמונה',
    'job_referrerMetaImg_description': 'גרור לכן תמונה (jpg, png)',
    'job_applicantMetaTitle': 'כותרת',
    'job_applicantMetaDescription': 'תאור',
    'job_applicantMetaImg': 'תמונה',
    'job_applicantMetaImg_description': 'גרור לכן תמונה (jpg, png)',

    'job_cover_description': 'גרור לכאן באנר שיחליף את באנר החברה עבור משרה זו (jpg, png, mp4)',
    'job_coverMobile_description': 'גרור לכאן באנר שיחליף את באנר החברה במכשירי מובייל עבור משרה זו (jpg, png, mp4)',
    'job_notSpecified': 'לא צוין',
    'job_preview_job': 'תצוגה מקדימה',

    'job_status_full': 'משרה מלאה',
    'job_status_part': 'משרה חלקית',
    'job_status_shifts': 'משמרות',
    'job_status_freelance': 'פרילנס',
    'job_status_relocation': 'רילוקיישן',

    'jobList_header_order': 'סדר',
    'jobList_header_title': 'כותרת משרה',
    'jobList_header_active': 'משרה פעילה',
    'jobList_header_created': 'נוצרה בתאריך',
    'jobList_header_appplicantCount': 'מועמדים',
    'jobList_header_viewCount': 'צפיות',
    'jobList_header_shareCount': 'שיתופים',
    'jobList_header_actions': 'פעולות',
    'jobList_header_innerRecruiter': 'מגייס/ת',
    'jobList_header_innerRecruiterManager': 'מנהל גיוס',
    'jobList_header_section': 'מחלקה',
    'jobList_header_internalId': 'מזהה פנימי',

    'jobList_promoteLabel': 'תנו PUSH',
    'jobList_pushTitle': 'תן PUSH למשרה',
    'jobList_pushConfirmationBold': 'האם אתה בטוח שאתה רוצה לבקש PUSH למשרה "{name}"?',
    'jobList_pushConfirmationReg': '{customerName} תדחף בשבילך את המשרה במדיה הדיגיטלית',
    'jobList_pushLabel': 'PUSH!',
    jobList_sendNotification: 'שליחת התראה',
    notifications_quota_warning: 'נגמרה לכם חבילת ההתראות.\n רוצים לחדש? צרו איתנו קשר',

    'jobsFilter_job': 'כותרת משרה',
    'jobsFilter_internalId': 'מזהה משרה פנימי',
    'jobsFilter_innerRecruiter': 'מגייס/ת',
    'jobsFilter_innerRecruiterManager': 'מנהל/ת גיוס',
    'jobsFilter_section': 'מחלקה',

    'jobList_actions_duplicate': 'שכפל משרה',

    'jobs_fullInfo_title': 'לפרטי המשרה המלאים',
    'job_inactive_title': 'סליחה, משרה זו איננה פעילה יותר',

    'referrer_internalId': 'מס\' עובד',
    'referrer_name': 'שם מלא',
    'referrer_id': 'טלפון',
    'referrer_phone': 'טלפון',
    'referrer_email': 'אימייל',
    'referrer_section': 'מחלקה',
    'referrer_birthday': 'יום הולדת',
    'referrer_comment': 'שדה 1',
    'referrer_comment2': 'שדה 2',
    'referrer_comment3': 'שדה 3',
    'referrer_applicants': 'מועמדים',
    'referrer_views': 'צפיות',
    'referrer_shares': 'שיתופים',
    'referrer_gdpr': 'מסכים לתנאים',
    'referrer_created': 'תאריך יצירה',
    'referrer_lastActive': 'פעיל לאחרונה',

    'create_new_referrer': 'יצר עובד חדש',
    'error_referrerAlreadyExists': 'עובד עם הטלפון הנוכחי כבר קיים במערכת',

    //menu
    'menu_menuItem_companies': 'חברות',
    'menu_menuItem_referrers': 'עובדים ממליצים',
    'menu_menuItem_details': 'פרטי חברה',
    'menu_menuItem_newCompany': 'הוסף חברה',
    menu_menuItem_apiKeys: 'מפתחות Api',
    menu_menuItem_terms: 'תקנון',
    menu_menuItem_notifications: 'התראות לעובדים',
    menu_menuItem_notifHistory: 'היסטורית התראות',
    'menu_menuItem_users': 'ניהול משתמשים',
    'menu_menuItem_userList': 'רשימת משתמשים',
    'menu_menuItem_newJob': 'משרה חדשה',
    'menu_menuItem_applicants': 'מועמדים',
    'menu_menuItem_dashboard': 'דשבורד',
    'menu_menuItem_companyJobs': 'משרות',
    'menu_menuItem_newUser': 'משתמש חדש',
    'menu_menuItem_allUsers': 'כל המשתמשים',
    'menu_menuItem_settings': 'הגדרות',
    'menu_menuItem_logout': 'התנתק',
    'companyNotFound': 'חברה לא נמצאה.',

    'navigation_addNewJob': 'הוסף משרה חדשה',

    'shareJobForm_title': 'הכנס/י את פרטייך כדי להעביר מועמדים דרכך',
    'shareJobForm_referrerId': 'טלפון',
    'shareJobForm_name': 'שם מלא',
    'shareJobForm_gdpr': 'אני מסכימ/ה לקבל הודעות ומסרים בהתאם {terms}',
    'shareJobForm_gdpr-link': 'לתקנון',
    'shareJobForm_submitLabel': 'צור לי לינק ייחודי',

    'generatedLink_name': 'היי {name}, ',
    'generatedLink_linkMessage': 'בחר/י כיצד לשתף את המשרה',
    'generatedLink_linkMessage_jobs': 'שתפ/י את המשרות שלנו',

    'applicantsFilter_submit': 'סנן',
    'applicantsFilter_referrerId': 'מספר טלפון - עובד ממליץ',
    'applicantsFilter_date': 'תאריך',
    'applicantsFilter_companyId': 'חברה',    
    'applicantsFilter_jobTitle': 'כותרת משרה',
    'applicantsFilter_status': 'סטטוס',
    
    'applicant_status_new': 'חדש',
    'applicant_status_irrelevant': 'לא רלוונטי',
    'applicant_status_recruited': 'גויס',
    'applicant_status_inProgress': 'בתהליך',

    'applicant_name': 'שם מלא',
    'applicant_referrerId': 'עובד ממליץ',
    'applicant_companyName': 'חברה',
    'applicant_jobId': 'מסֿֿ\' משרה',
    'applicant_status': 'סטטוס',
    'applicant_email': 'אימייל',
    'applicant_phone': 'טלפון',
    'applicant_created': 'תאריך',
    'applicant_cv': 'קו"ח',
    'applicant_cvFile': 'קובץ קו"ח',
    'applicant_aboutMe': 'קצת עלי',
    'applicant_site': 'קישור לפרופיל LinkedIn',
    'applicant_gdpr': 'מסכים לתנאים',

    'applicantList_notFound': 'אין עדיין מועמדים :(',
    'applicantList_cvFile': 'קובץ',

    'createApplicationForm_showMore': 'אופציות נוספות',
    'createApplicationForm_submit': 'הגשת מועמדות',
    'createApplicationForm_applied': 'הגשת מועמדותך נקלטה במערכת!',
    'createApplicationForm_goodLuck': 'בהצלחה!',
    'createApplicationForm_moreJobsTitle': 'הצצת במשרות הנוספות שלנו?',
    'createApplicationForm_moreJobsSubtitle': 'אולי יש משרה שמתאימה לחברים שלך',
    'createApplicationForm_cv': 'העלאת קורות חיים (PDF/docx)',
    'createApplicationForm_gdpr': 'אני מסכימ/ה לקבל הודעות ומסרים בהתאם {terms}',
    'createApplicationForm_gdpr-link': 'לתקנון',

    'users_usersNotFound': 'לא יצרת עדיין משתמשים',
    userList_header_mfa: 'MFA',
    'userList_header_displayName': 'שם',
    'userList_header_email': 'אימייל',
    'userList_header_companyName': 'חברה',
    'userList_deleteTItle': 'מחיקת משתמש',
    'userList_deleteConfirmationBold': 'האם אתה בטוח שאתה רוצה למחוק את {name}?',
    'userList_deleteConfirmationReg': 'משתמש זה לא יוכל להתחבר יותר למערכת',

    'create_user_submit': 'צור משתמש',
    'edit_user_submit': 'עדכן משתמש',
    'createEditUser_label_displayName': 'שם',
    'createEditUser_label_email': 'אימייל',
    'createEditUser_label_companyId': 'חברה',
    'createEditUser_label_password': 'סיסמה',
    createEditUser_label_mfa: 'דרוש MFA',

    apiKeysList_header_name: 'שם',
    apiKeysList_header_enabled: 'פעיל',
    apiKeysList_header_companyName: 'שם חברה',
    apiKeysList_deleteTitle: 'מחיקת מפתח Api',
    apiKeysList_deleteConfirmationBold: 'האם אתה בטוח שאתה רוצה למחוק את {name}?',
    apiKeysList_deleteConfirmationReg: 'פעולה זו לא ניתנת לשחזור',

    create_apiKey_submit: 'יצירת מפתח Api',
    edit_apiKey_submit: 'עדכון מפתח Api',
    createEditApiKey_label_name: 'שם',
    createEditApiKey_label_email: 'Email',
    createEditApiKey_label_enabled: 'פעיל',
    createEditApiKey_label_companyId: 'שם חברה',
    createEditApiKey_label_id: 'מפתח גישה',

    'actions_edit': 'ערוך',
    'actions_cancel': 'בטל',
    'actions_create': 'צור',

    'fileUpload_fileSelected': 'קובץ נבחר',
    'imageUpload_replace': 'גרור תמונה אחרת (או לחץ) כדי להחליף תמונה',
    'imageUpload_prefered': 'גודל מומלץ - {preferedDimentions} (עד {maxSize})',

    'form_requiredField': 'שדה זה הינו שדה חובה',
    'form_unsavedChanges': 'ישנם שינויים שלא שמרת. האם ברצונך לעזוב את הדף?',

    'contact_title': 'צור קשר והתראות',
    'incentives_title': 'תמריצים',
    'settings_title': 'הגדרות',
    'referrer_explanation_title': 'הסבר לעובד ממליץ',
    'jobs_link_sub_cmp': 'לדף משרות',
    'job_location_and_map_title': 'מיקום המשרה',
    'company_location_and_map_title': 'מיקום החברה',
    'general_details_title': 'פרטים כלליים',

    'validation_errors_present': 'קיימות שגיאות בטופס',
    'validation_required': 'שדה חובה',
    'validation_minLength': 'מינימום {minLength} תוים',
    'validation_exactLength': 'אורך צריך להיות {length} תוים',
    'validation_email': 'אימייל אינו תקין',
    'validation_identification_unavailable': 'המזהה תפוס',
    'validation_identification_available': 'המזהה פנוי',
    'validation_cv': 'ניתן להעלות רק קבצי PDF ו-docx',
    'validation_invalid_media_file': 'גודל/סוג קובץ אינו חוקי',

    'operation_success': 'הפעולה הושלמה בהצלחה',
    'operation_failure': 'הפעולה נכשלה',

    'dashboard_totalJobs': 'משרות במערכת',
    'dashboard_activeJobs': 'משרות פעילות',
    'dashboard_inactiveJobs': 'משרות לא פעילות',
    'dashboard_totalCompanies': 'חברות',
    'dashboard_referrers': 'עובדים שנרשמו',
    'dashboard_activeEmployees': 'אחוז עובדים שנרשמו',
    'dashboard_shares': 'שיתופים',

    'dashboard_column_title': 'כותרת המשרה',
    'dashboard_column_viewed': 'צפיות',
    'dashboard_column_applied': 'מועמדים',
    'dashboard_column_shares': 'שיתופים',
    'dashboard_column_name': 'שם',
    'dashboard_column_email': 'אימייל',
    'dashboard_column_phone': 'טלפון',

    'dashboard_title_totalViews': 'צפיות',
    'dashboard_title_totalApplicants': 'מועמדים',

    'admin_exportCSV': 'יצא ל-CSV',
    
    'referrerWorth_title': 'למה זה כדאי לי?',
    'return_to_alljobs': 'למשרות נוספות',

    // draftjs
    // Generic
    'generic.add': 'הוסף',
    'generic.cancel': 'בטל',
    // BlockType
    'components.controls.blocktype.h3': 'כותרת',
    'components.controls.blocktype.normal': 'רגיל',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // History
    'components.controls.history.history': 'הסטוריה',
    'components.controls.history.undo': 'בטל',
    'components.controls.history.redo': 'חזור',
    // TextAlign
    'components.controls.textalign.textalign': 'יישור טקסט',
    'components.controls.textalign.left': 'ישר לשמאל',
    'components.controls.textalign.center': 'מרכז',
    'components.controls.textalign.right': 'יישור לימין',
    'components.controls.textalign.justify': 'יישור לשני הצדדים',
    // Inline
    'components.controls.inline.bold': 'מודגש',
    'components.controls.inline.italic': 'מוטה',
    'components.controls.inline.underline': 'קו תחתון',
    'components.controls.inline.strikethrough': 'קו חוצה',
    'components.controls.inline.monospace': 'רווח אחיד',
    'components.controls.inline.superscript': 'טקסט עליון',
    'components.controls.inline.subscript': 'טקסט תחתון',

    //react-dates
    'date_startDate': 'מתאריך',
    'date_endDate': 'עד תאריך',

    'shareableLinks_fb': 'פייסבוק',
    'shareableLinks_ln': 'לינקדאין',
    'shareableLinks_wa': 'וואטסאפ',
    'shareableLinks_url': 'העתק קישור',
    'shareableLinks_coppied': 'הועתק',
    'shareableLinks_manualCopy': 'CTRL+C כדי להעתיק',

    'footer_terms': 'תקנון',
    'footer_rights': 'כל הזכויות שמורות ל-PRO-MAN',
    'footer_developed': 'פיתוח: SCOTTY',
    
    'cropModal_title': 'חתוך תמונה',
    'cropModal_leaveUncropped': 'אל תחתוך',
    'cropModal_crop': 'חתוך את התמונה',
    'cropModal_explain': 'גרור את התמונה למיקום הרצוי. השתמש בגלגלת על מנת לעשות זום',
    'cropModal_notSupported': 'חיתוך וידאו אינו נתמך',
    'cropModal_previewWeb': 'כך תראה התמונה במחשב:',
    'cropModal_previewMobile': 'כך תראה התמונה בטלפון:',
    'cropModal_previews_title': 'תצוגה מקדימה',

    'sharesList_header_referrer': 'עובד ממליץ',
    'sharesList_header_date': 'תאריך',
    'sharesList_header_share': 'סוג שיתוף',
    'sharesList_header_job': 'משרה ששותפה',
    'sharesList_share_wa': 'ווטסאפ',
    'sharesList_share_fb': 'פייסבוק',
    'sharesList_share_ln': 'לינקדאין',
    'sharesList_share_url': 'העתק קישור',
    'sharesList_jobListShare': '<דף משרות>',

    'settings_terms': 'תקנון',

    'importCsv_title': 'יבא עובדים מקובץ csv',
    'importCsv_fileUploadField': 'גרור לכאן קובץ Excel/CSV של העובדים',
    'importCsv_fileUploadExample': 'קובץ csv לדוגמה',
    'importCsv_advanced_toggle': 'הגדרות מתקדמות',
    'importCsv_status_success': 'יבוא מתאריך {date} הושלם בהצלחה. יתכן וידרשו מספר דקות נוספות עד שיהיה ניתן לראות את השינויים',
    'importCsv_status_inProgress': 'יבוא מתאריך {date} נמצא בתהליך',
    'importCsv_status_failure': 'יבוא מתאריך {date} נכשל.',
    'importCsv_importInProgress': 'יבוא העובדים החל. תהליך זה יכול לקחת כמה דקות...',
    'importCsv_failureReason_invalidPhone': 'שדה "טלפון" אינו חוקי בשורה: {line}. (יש להזין 10 ספרות)',
    'importCsv_failureReason_invalidEmail': 'שדה "אימייל" אינו חוקי בשורה: {line}.',
    'importCsv_failureReason_invalidGdpr': 'שדה "מסכים לתנאים" אינו חוקי בשורה: {line}. (יש להזין true/false/TRUE/FALSE)',
    'importCsv_failureReason_invalidBirthday': 'שדה "יום הולדת" אינו חוקי בשורה: {line}. (יש להזין בפורמט 27/10/2001)',
    'importCsv_failureReason_contactAdmin': 'אנא פנה למנהל המערכת.',
    'importCsv_overrideWarning': 'שימו לב: ייבוא קובץ עובדים מוחק את טבלת העובדים הקיימת במערכת.',
    'importCsv_ignoreFirstLine': 'התעלם משורה ראשונה',

    'referrersFilter_findReferrer': 'מצא עובד',
    'referrersFilter_segmentReferrers': 'סנן עובדים ליצוא',
    'referrersFilter_fieldChooser_title': 'שדה תאריך',

    'template_companyName': 'שם חברה',
    'template_jobInternalId': 'מזהה משרה',
    'template_jobTitle': 'כותרת המשרה',
    'template_referrerPhone': 'טלפון עובד ממליץ',
    'template_referrerName': 'שם עובד ממליץ',
    'template_applicantName': 'שם מועמד',

    'locale_en': 'אנגלית',
    'locale_he': 'עברית',

    'not_supported': 'פאנל הניהול איננו זמין באינטרנט אקספלורר. יש להשתמש בדפדפן מתקדם יותר',

    notifications_enabled: 'התראות מופעלות',
    notifications_disabled: 'התראות מושבתות',
    notifications_status_title: 'סטטוס התראות',
    notifications_disabled_warning: 'העובדים שלכם לא מקבלים התראות על משרות חדשות ומועמדים שנקלטו דרכם! מעוניינים גם? - צרו איתנו קשר',
    notif_amountLeft: 'כמות התראות שנותרה',
    notif_monthlyBank: 'חבילה חודשית',
    notif_monthlyBank_explain: 'כל חודש כמות ההתראות שלכם עולה חזרה לסכום החבילה החודשית שלכם',
    notifications_templates_title: 'תבניות התראה לעובדים',
    notif_template_newJob_title: 'תבנית למשרה חדשה',
    notif_template_newApplicant_title: 'תבנית למועמד חדש',
    notif_template_title: 'תבנית לכותרת ההתראה',
    notif_template_body: 'תבנית לגוף ההתראה',

    create_notif_title: 'כותרת התראה',
    create_notif_body: 'גוף התראה',
    create_notif_img: 'תמונת ההתראה',
    create_notif_approximate: `ההתראה תשלח {number, plural,
        =0 {למספר עובדים לא ידוע}
        one {לעובד אחד}
        other {לכ-# עובדים}
    }`,

    send_job_notification: 'שליחת התראה למשרה',
    notif_request_register_title: 'קבלו התראות כשחבר שלכם נרשם דרכיכם וכשעולות משרות חדשות!',
    notif_revoke: 'הסר התראות',
    notif_revoked: 'התראות בוטלו',
    notif_cantSendNotification: 'אין לכם חבילת התראות\nמעוניינים גם? צרו איתנו קשר',

    notifHistory_header_type: 'סוג',
    notifHistory_header_title: 'כותרת',
    notifHistory_header_created: 'תאריך יצירה',
    notifHistory_header_received: 'כמות שליחות',
    notifHistory_header_opened: 'כמות פתיחות',

    notif_type_newJob: 'משרה חדשה',
    notif_type_newApplicant: 'מועמד חדש',
    
    referrersList_disableRevokeNotifTitle: 'ביטול התראות לעובד',
    referrersList_disableRevokeNotifBold: 'האם לבטל התראות לעובד {name}?',
    referrersList_disableRevokeNotifReg: 'פעולה זו הינה בלתי הפיכה ועובד זה לא יוכל לקבל התראות בהמשך באמצעות הלינק שיצר',
    referrersList_revokeLabel: 'ביטול התראות',

    previewRatio_fb: 'Facebook',
    previewRatio_wa: 'Whatsapp',
}
