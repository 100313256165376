import React from 'react';
import classnames from 'classnames';
import Flex from 'components/Flex';

export default function ElevatedBox({className, component, children, withBottomLine, noPadding, ...restProps}) {
    const classes = classnames('elevated-box', {'elevated-box--noPadding': noPadding}, className);
    const Component = component || 'section';
    return (
        <Component className={classes} {...restProps}>
            <Flex stretch className="elevated-box__content">
                {children}
            </Flex>
            {withBottomLine && <div className="elevated-box__bottomLine" />}
        </Component>
    )
}
