import axios from 'services/axios';
import firebase from 'services/firebase';
import {put, call, takeLatest, select} from 'redux-saga/effects';
import {Selectors as AuthSelectors} from 'modules/auth';
import {Actions, ActionTypes as UsersActionTypes, Selectors as UsersSelectors} from 'modules/users';
import config from 'config';

function* addUser(action) {
    yield put(Actions.ADD_USER_REQUEST());
    try {
        const {displayName, email, password, companyId, mfa} = action.payload;

        const data = {user: {displayName, email, password, mfa}, companyId};
        if ('mfa' in action.payload) {
            data.user.mfa = mfa;
        }
        const result = yield call(axios.post, 'customers/me/users', data);
        yield put(Actions.ADD_USER_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ADD_USER_FAILURE(err));
    }
}

function* updateUser(action) {
    yield put(Actions.EDIT_USER_REQUEST());
    const uid = yield select(UsersSelectors.matchingUserIdSelector);
    try {
        const {displayName, email, password, mfa} = action.payload;

        const editUserPayload = {displayName, email};
        if (password) {
            editUserPayload.password = password;
        }
        if ('mfa' in action.payload) {
            editUserPayload.mfa = mfa;
        }
        const result = yield call(axios.patch, `customers/me/users/${uid}`, editUserPayload);
        yield put(Actions.EDIT_USER_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.EDIT_USER_FAILURE(err));
    }
}

function* deleteUser(action) {
    yield put(Actions.DELETE_USER_REQUEST());
    const {uid} = action.payload;
    try {
        yield call(axios.delete, `customers/me/users/${uid}`);
        yield put(Actions.DELETE_USER_SUCCESS(uid));
    } catch (err) {
        yield put(Actions.DELETE_USER_FAILURE(err));
    }
}


const fetchUsersLogic = async (customerId, startAfter) => {
    let usersQueryBuilder = firebase.firestore()
    .collection('customers')
    .doc(customerId)
    .collection('users')
    .orderBy('created')
    .limit(config.usersPagination);

    if (startAfter) {
        usersQueryBuilder = usersQueryBuilder.startAfter(startAfter);
    }
    const query = await usersQueryBuilder.get();
    const last = query.docs[query.docs.length - 1];
    const users = query.docs.map(d => ({...d.data(), uid: d.id}));
    return {users, last};
}

function* fetchUsers() {
    yield put(Actions.FETCH_USERS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const {users, last} = yield call(fetchUsersLogic, customerId);
        yield put(Actions.FETCH_USERS_SUCCESS({users, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_USERS_FAILURE(err));
    }
}

function* fetchMoreUsers() {
    yield put(Actions.FETCH_MORE_USERS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const lastSnap = yield select(UsersSelectors.lastUserSelector);
        const {users, last} = yield call(fetchUsersLogic, customerId, lastSnap);
        yield put(Actions.FETCH_MORE_USERS_SUCCESS({users, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_USERS_FAILURE(err));
    }
}

function* fetchMatchingUser() {
    yield put(Actions.FETCH_MATCHING_USER_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);        
        const uid = yield select(UsersSelectors.matchingUserIdSelector);
        const userDoc = yield firebase.firestore()
                                        .collection('customers')
                                        .doc(customerId)
                                        .collection('users')
                                        .doc(uid)
                                        .get();
        const user = {...userDoc.data(), uid: userDoc.id};
        yield put(Actions.FETCH_MATCHING_USER_SUCCESS(user));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_USER_FAILURE(err));
    }
}

export default [
    takeLatest([UsersActionTypes.FETCH_USERS_ACTION, UsersActionTypes.ADD_USER_SUCCESS, UsersActionTypes.EDIT_USER_SUCCESS], fetchUsers),
    takeLatest(UsersActionTypes.FETCH_MORE_USERS_ACTION, fetchMoreUsers),
    takeLatest(UsersActionTypes.ADD_USER_ACTION, addUser),
    takeLatest(UsersActionTypes.EDIT_USER_ACTION, updateUser),
    takeLatest(UsersActionTypes.DELETE_USER_ACTION, deleteUser),
    takeLatest(UsersActionTypes.FETCH_MATCHING_USER_ACTION, fetchMatchingUser),
]