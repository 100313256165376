import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_STATS_ACTION',
    'FETCH_STATS_REQUEST',
    'FETCH_STATS_SUCCESS',
    'FETCH_STATS_FAILURE',

    'FETCH_COMPANY_STATS_ACTION',
    'FETCH_COMPANY_STATS_REQUEST',
    'FETCH_COMPANY_STATS_SUCCESS',
    'FETCH_COMPANY_STATS_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
