import React, {Component} from 'react';
import Modal from 'components/Modal';
import Title from 'components/Title';
import Flex from 'components/Flex';
import Button from 'components/Button';
import classnames from 'classnames';

export default class ConfirmationModal extends Component {
    renderContent = () => {
        const {children, contentBold, contentReg} = this.props;
        if (children) {
            return (
                <div className="confirmation-modal__content">
                    {children}
                </div>
            );
        }
        return (
            <div className="confirmation-modal__content">
                <Flex>
                    <Title>{contentBold}</Title>
                    <Title regular>{contentReg}</Title>
                </Flex>
            </div>
        );
    }
    render = () => {
        const {children, title, contentBold, contentReg, className,
                primaryLabel, onPrimaryClick, secondaryLabel, onSecondaryClick, primaryDisabled,
                ...rest
        } = this.props;
        const content = this.renderContent();
        return (
            <Modal
                {...rest}
                className={classnames('confirmation-modal', className)}
                noPadding
            >
                <div className="confirmation-modal__title">
                    {title && <Flex centerChildren><Title>{title}</Title></Flex>}
                </div>
                {content}
                <Flex justify="end" horizontal component="footer" className="confirmation-modal__footer">
                    {secondaryLabel && <Button text={secondaryLabel} onClick={onSecondaryClick} />}
                    {primaryLabel && <Button disabled={primaryDisabled} primary text={primaryLabel} onClick={onPrimaryClick} />}
                </Flex>
            </Modal>
        );
    }
}
