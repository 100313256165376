import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
import {Sagas as ToastrSagas} from 'modules/toastr';
import {reducer as authReducer} from 'modules/auth';
import {reducer as companiesReducer} from 'modules/companies';
import {reducer as companyJobsReducer} from 'modules/companyJobs';
import {reducer as referrerReducer} from 'modules/referrer';
import {reducer as applicantReducer} from 'modules/applicant';
import {reducer as usersReducer} from 'modules/users';
import {reducer as apiKeysReducer} from 'modules/apiKeys';
import {reducer as statsReducer} from 'modules/stats';
import {reducer as customerReducer} from 'modules/customer';
import {reducer as sharesReducer} from 'modules/shares';
import {reducer as i18nReducer} from 'modules/i18n';
import {reducer as notifsReducer} from 'modules/notifications';

import authSagas from 'modules/auth/authSagas';
import companiesSagas from 'modules/companies/companiesSagas';
import companyJobsSagas from 'modules/companyJobs/companyJobsSagas';
import referrerSagas from 'modules/referrer/referrerSagas';
import applicantSagas from 'modules/applicant/applicantSagas';
import usersSagas from 'modules/users/usersSagas';
import apiKeysSagas from 'modules/apiKeys/apiKeysSagas';
import statsSagas from 'modules/stats/statsSagas';
import customerSagas from 'modules/customer/customerSagas';
import sharesSagas from 'modules/shares/sharesSagas';
import i18nSagas from 'modules/i18n/i18nSagas';
import tracking from 'modules/tracking/trackingSagas';
import notifsSagas, {watchers as notifWatchers} from 'modules/notifications/notifsSagas';

import { all, spawn } from 'redux-saga/effects'

export const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  companies: companiesReducer,
  companyJobs: companyJobsReducer,
  referrer: referrerReducer,
  applicant: applicantReducer,
  users: usersReducer,
  apiKeys: apiKeysReducer,
  stats: statsReducer,
  toastr: toastrReducer,
  customer: customerReducer,
  shares: sharesReducer,
  i18n: i18nReducer,
  notifs: notifsReducer,
})

export function * rootSaga() {
  yield spawn(notifWatchers);
  yield all([
      ...companiesSagas,
      ...authSagas,
      ...companyJobsSagas,
      ...referrerSagas,
      ...applicantSagas,
      ...usersSagas,
      ...apiKeysSagas,
      ...statsSagas,
      ...ToastrSagas,
      ...customerSagas,
      ...sharesSagas,
      ...i18nSagas,
      ...notifsSagas,
      ...tracking,
  ])
}