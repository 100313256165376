import {connect, createActionTypes, createActionCreators} from 'utils/reduxUtils';
import UsersList from 'components/UsersList';
import {Actions as UsersActions, Selectors as UsersSelectors} from 'modules/users';

const typesArray = [
    'USER_LIST_ON_USER_CLICK',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);

const mapDispatchToProps = {
    onMounting: UsersActions.FETCH_USERS_ACTION,
    onDeleteUser: UsersActions.DELETE_USER_ACTION,
    onUserClick: (user) => Actions.USER_LIST_ON_USER_CLICK(user),
    loadMore: UsersActions.FETCH_MORE_USERS_ACTION,
};

const mapStateToProps = state => {
    return {
        users: UsersSelectors.enhancedUsersSelector(state),
        hasMore: UsersSelectors.hasMoreUsersSelector(state),
        loading: UsersSelectors.fetchingSelector(state) || UsersSelectors.loadingSelector(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
