import { createSelector } from 'reselect';

export const sliceSelector = (state) => state.auth;

export const customerIdSelector = state => state.auth.customer && state.auth.customer.id;
export const isLoggedInSelector = state => !!state.auth.isLoggedIn;
export const isLoadingSelector = state => !!state.auth.loading;
export const loginFailureMessageSelector = state => state.auth.loginFailureMessage;
export const limitedToCompanySelector = state => state.auth.companyId;
export const loginScreen = state => state.auth.screen;
export const mfaQrCode = createSelector(sliceSelector, state => {
    if (!state.mfaTokenUri) {
        return null;
    }
    return `https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${state.mfaTokenUri}`
})
export const loginProviders = createSelector(sliceSelector, state => state.loginProviders || [])
