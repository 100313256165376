import {takeLatest, select, fork, put, take} from 'redux-saga/effects';
import {Selectors as CompanySelectors, ActionTypes as CompanyActionTypes} from 'modules/companies';
import {Selectors as JobsSelectors, ActionTypes as JobsActionTypes} from 'modules/companyJobs';
import {Actions} from './trackingActions';
import {Selectors as AuthSelectors} from 'modules/auth';
import {getTracker} from './trackingFactory';
import {LOCATION_CHANGE} from 'react-router-redux';
import {ActionTypes as ApplicantActionTypes} from 'modules/applicant';
import _ from 'lodash';

function* trackAction(action, actionKey) {
    if (!action) {return;}
    const isLoggedIn = yield select(AuthSelectors.isLoggedInSelector);

    if (!action.trackers) {
        console.warn(`${actionKey} has no trackers`);
        return;
    }
    for (let i = 0; i < action.trackers.length; i++) {
        const trackerOpts = action.trackers[i];
        if (isLoggedIn && trackerOpts.suppressAdmin) {
            return;
        }
        yield put(Actions.TRACKING_ACTION(trackerOpts));
        const tracker = getTracker(trackerOpts.type);
        tracker(trackerOpts);
    }
    
}

function* trackEntityActions(entity, actionKeys) {
    try {
        if (!entity) {
            console.warn(`Will not track [${actionKeys.join(', ')}]. Entity not found`);
        }
        for (let i = 0; i < actionKeys.length; i++) {
            const actionKey = actionKeys[i];
            const action = _.get(entity, `tags.${actionKey}`);
            yield fork(trackAction, action, actionKey);
        }
    } catch (err) {
        console.error(err);
    }
}

function* trackCompanyActions(actionKeys) {
    try {
        let company = yield select(CompanySelectors.matchingCompanySelector);
        if (!company) {
            yield take([CompanyActionTypes.FETCH_COMPANIES_SUCCESS, CompanyActionTypes.FETCH_MATCHING_COMPANY_SUCCESS]);
        }
        company = yield select(CompanySelectors.matchingCompanySelector);
        yield fork(trackEntityActions, company, actionKeys);
    } catch (err) {
        console.error(err);
    }
}

function* trackJobActions(actionKeys) {
    try {
        let job = yield select(JobsSelectors.matchingJobSelector);
        if (!job) {
            yield take([JobsActionTypes.FETCH_MATCHING_JOB_SUCCESS]);
        }
        job = yield select(JobsSelectors.matchingJobSelector);
        yield fork(trackEntityActions, job, actionKeys);
    } catch (err) {
        console.error(err);
    }
}

function* trackPageView(action) {
    if (/^(\/r)?\/companies\//.test(action.payload.pathname)) {
        // starts with /r/companies or /companies
        yield fork(trackCompanyActions, ['init', 'pageView']);
    }
    if (/^(\/r)?\/companies\/\w+\/jobs\/.+/.test(action.payload.pathname)) {
        // starts with /r/companies or /companies
        yield fork(trackJobActions, ['init', 'pageView']);
    }
}

export default [
    takeLatest(LOCATION_CHANGE, trackPageView),
    takeLatest(ApplicantActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS, trackCompanyActions, ['leadForm']),
    takeLatest(ApplicantActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS, trackJobActions, ['leadForm']),
]