import axios from 'services/axios';
import firebase from 'services/firebase';
import {delay} from 'redux-saga';
import {put, takeLatest, select, call} from 'redux-saga/effects';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Actions, ActionTypes as CompanyJobsActionTypes, Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as AuthSelectors} from 'modules/auth';
import config from 'config';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import {createCSV, prepareObjectToCsv} from 'utils/transformUtils';
import {ActionTypes as CreateNotifModalAT} from 'containers/createNotifModal/createNotifModalActions';
import fileSaver from 'file-saver';

const covertToClientSideJob = (jobDoc) => {
    const result = {...jobDoc.data(), id: jobDoc.id};
    if (result.type) result.type = Object.keys(result.type);
    return result;
}

const fetchJobsLogic = async (customerId, companyId, filter, pagination = {}, fetchAll) => {
    const collection = customerId ? 'jobs-private' : 'jobs';
    let jobsQueryBuilder = await firebase.firestore()
    .collection(collection)
    .where('companyId', '==', companyId)
    .orderBy('order', 'desc')
    .orderBy('created', 'desc');

    if (!fetchAll) {
        jobsQueryBuilder = jobsQueryBuilder.limit(config.jobsPagination);
    }

    const filterKeys = ['title', 'internalId', 'innerRecruiter', 'innerRecruiterManager', 'section'];
    jobsQueryBuilder = filterKeys.reduce((builder, key) => {
        if (filter[key]) {
            return builder.where(key, '==', filter[key]);
        }
        return builder;
    }, jobsQueryBuilder);

    if (customerId) {
        jobsQueryBuilder = jobsQueryBuilder.where('customerId', '==', customerId);
    }
    if (filter.activeOnly) {
        jobsQueryBuilder = jobsQueryBuilder.where('active', '==', true);
    }
    if (pagination.startAfter) {
        jobsQueryBuilder = jobsQueryBuilder.startAfter(pagination.startAfter);
    }
    if (pagination.endAt) {
        jobsQueryBuilder = jobsQueryBuilder.endAt(pagination.endAt);
    }
    const query = await jobsQueryBuilder.get();
    const jobs = query.docs.map(covertToClientSideJob);
    const last = query.docs[query.docs.length - 1];
   return {jobs, last};
}

function* requestPush(action) {
    yield put(Actions.JOB_PUSH_REQUEST_REQUEST());
    try {
        const {id, companyId} = action.payload;
        yield call(axios.post, `customers/me/companies/${companyId}/jobs/${id}/push`);
        yield put(Actions.JOB_PUSH_REQUEST_SUCCESS(id));
    } catch (err) {
        console.log(err);
        yield put(Actions.JOB_PUSH_REQUEST_FAILURE(err));
    }
}

function* fetchCompanyJobs({activeOnly}) {
    try {
        const lastSnap = yield select(JobsSelectors.lastJobSelector);
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const filter = yield select(JobsSelectors.filterSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);

        yield put(Actions.FETCH_COMPANY_JOBS_REQUEST({companyId}));
        const extendedFilter = {activeOnly, ...filter};
        const pagination = {endAt: lastSnap};
        const {jobs, last} = yield fetchJobsLogic(customerId, companyId, extendedFilter, pagination);
        yield put(Actions.FETCH_COMPANY_JOBS_SUCCESS({jobs, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_JOBS_FAILURE(err));
    }
}

function* fetchMoreJobs(action, activeOnly) {
    yield put(Actions.FETCH_MORE_JOBS_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const lastSnap = yield select(JobsSelectors.lastJobSelector);
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const filter = {activeOnly};
        const pagination = {startAfter: lastSnap};
        const {jobs, last} = yield fetchJobsLogic(customerId, companyId, filter, pagination);
        yield put(Actions.FETCH_MORE_JOBS_SUCCESS({jobs, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_JOBS_FAILURE(err));
    }
}

export const fetchJobByIdLogic = async (jobId) => {
    const publicDoc = await firebase.firestore().collection('jobs').doc(jobId).get();
    if (!publicDoc.exists) {
        throw new Error(`Job ${jobId} doesn't exist`);
    }
    const job = covertToClientSideJob(publicDoc);
    return job;
}

export function* fetchJobById(companyId, jobId) {
    yield put(Actions.FETCH_JOB_BY_ID_REQUEST());
    try {
        const job = yield fetchJobByIdLogic(jobId);
        yield put(Actions.FETCH_JOB_BY_ID_SUCCESS(job));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_JOB_BY_ID_FAILURE(err));
    }
}

function* fetchMatchingJob(fetchPrivate) {
    yield put(Actions.FETCH_MATCHING_JOB_REQUEST());
    try {
        const jobId = yield select(JobsSelectors.matchingJobIdSelector);
        let promise;
        if (fetchPrivate) {
            promise = firebase.firestore().collection('jobs-private').doc(jobId).get();
        } else {
            promise = firebase.firestore().collection('jobs').doc(jobId).get()
        }
        const doc = yield promise;
        if (!doc.exists) {
            throw new Error(`Job ${jobId} doesn't exist`);
        }
        const job = covertToClientSideJob(doc);
        yield put(Actions.FETCH_MATCHING_JOB_SUCCESS(job));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_JOB_FAILURE(err));
    }
}

function* createNewJob(action) {
    yield put(Actions.CREATE_JOB_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {sendNotif, ...newJob} = action.payload;
        const result = yield call(axios.post, `customers/me/companies/${companyId}/jobs`, newJob);
        
        yield put(Actions.CREATE_JOB_SUCCESS(result.data, {sendNotif}));
    } catch (err) {
        console.log(err);
        yield put(Actions.CREATE_JOB_FAILURE(err));
    }
}

function* duplicateJob(action) {
    yield put(Actions.CREATE_JOB_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {jobId, sendNotif, ...newJob} = action.payload;
        const result = yield call(axios.post, `customers/me/companies/${companyId}/jobs/${jobId}/duplicate`, newJob);
        
        yield put(Actions.CREATE_JOB_SUCCESS(result.data, {sendNotif}));
    } catch (err) {
        console.log(err);
        yield put(Actions.CREATE_JOB_FAILURE(err));
    }
}

function* editJob(action) {
    yield put(Actions.EDIT_JOB_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const job = yield select(JobsSelectors.matchingJobSelector);
        const newJob = {...action.payload};
        yield call(axios.patch, `customers/me/companies/${companyId}/jobs/${job.id}`, newJob);
        
        yield put(Actions.EDIT_JOB_SUCCESS({...job, ...newJob}));
    } catch (err) {
        console.log(err);
        yield put(Actions.EDIT_JOB_FAILURE(err));
    }
}

function* logJobView() {
    const isLoggedIn = yield select(AuthSelectors.isLoggedInSelector);
    const isReferrerView = yield select(NavigationSelectors.isReferrerViewSelector);
    if (isLoggedIn || isReferrerView) {
        return;
    }
    const beforeJobId = yield select(JobsSelectors.matchingJobIdSelector);
    yield delay(config.viewedJobDelay);
    const afterJobId = yield select(JobsSelectors.matchingJobIdSelector);
    if (beforeJobId !== afterJobId) {
        return;
    }
    const viewedJobs = JSON.parse(localStorage.getItem(config.viewedJobsKey) || '[]');
    const currentTime = Date.now();
    const timeout = config.viewedJobsTimeout;
    const newJobs = viewedJobs.filter(j =>  currentTime - j.time < timeout);
    if (newJobs.find(j => j.id === afterJobId)) {
        return;
    }
    newJobs.push({id: afterJobId, time: currentTime});
    localStorage.setItem(config.viewedJobsKey, JSON.stringify(newJobs));
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    const referrerLink = yield select(ReferrerSelectors.matchingReferrerLinkSelector);
    try {
        yield call(axios.post, `companies/${companyId}/jobs/${afterJobId}/view`, {referrerLink});
    } catch (err) {
        console.log(err);
    }
}

function* generateJobsReport() {
    yield put(Actions.GENERATE_JOBS_REPORT_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const filter = yield select(JobsSelectors.filterSelector);
        const columns = {
            internalId: 'Internal Id',
            title: 'Job Title',
            active: 'Is Active',
            section: 'section',
            createdFormatted: 'Created At',
            applied: 'Total Applicants',
            viewed: 'Total Views',
            shares: 'Total Shares',
            url: 'Url Shares',
            fb: 'Facebook Shares',
            ln: 'LinkedIn Shares',
            wa: 'Whatsapp Shares',
            innerRecruiter: 'Recruiter',
            innerRecruiterManager: 'Recruiter Manager',
        };
        const {jobs} = yield fetchJobsLogic(customerId, companyId, filter, undefined, true);
        const formattedJobs = jobs.map(prepareObjectToCsv)
        const csv = yield createCSV(formattedJobs, {columns});
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
        yield put(Actions.GENERATE_JOBS_REPORT_SUCCESS());
        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `jobs-${currentDate}.csv`);
    } catch (err) {
        console.error(err);
        yield put(Actions.GENERATE_JOBS_REPORT_FAILURE(err));
    }
}

export default [
    takeLatest([
        CompanyJobsActionTypes.ADMIN_COMPANY_JOBS_MOUNTING,
        CompanyJobsActionTypes.ADMIN_COMPANY_JOBS_COMPANY_CHANGED,
        CompanyJobsActionTypes.FILTER_ADMIN_JOB_LIST,
    ], fetchCompanyJobs),
    takeLatest(CompanyJobsActionTypes.COMPANY_JOBS_MOUNTING, fetchCompanyJobs, {activeOnly: true}),
    takeLatest(CompanyJobsActionTypes.PUBLIC_LOAD_MORE_JOBS, fetchMoreJobs, true),
    takeLatest(CompanyJobsActionTypes.CREATE_JOB_ACTION, createNewJob),
    takeLatest(CompanyJobsActionTypes.ADMIN_DUPLICATE_JOB_ACTION, duplicateJob),
    takeLatest(CompanyJobsActionTypes.EDIT_JOB_ACTION, editJob),
    takeLatest([
        CompanyJobsActionTypes.EDIT_JOB_MOUNTING,
        CreateNotifModalAT.CREATE_NOTIF_MODAL_MOUNT,
    ], fetchMatchingJob, true),
    takeLatest(CompanyJobsActionTypes.PUBLIC_JOB_MOUNTING, fetchMatchingJob, false),
    takeLatest(CompanyJobsActionTypes.LOAD_MORE_JOBS, fetchMoreJobs),
    takeLatest(CompanyJobsActionTypes.PUBLIC_JOB_MOUNTING, logJobView),
    takeLatest(CompanyJobsActionTypes.JOB_PUSH_REQUEST_ACTION, requestPush),
    takeLatest(CompanyJobsActionTypes.GENERATE_JOBS_REPORT_ACTION, generateJobsReport),
]