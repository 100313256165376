import csvStringify from 'csv-stringify/lib/es5'; // ie
import csvParse from 'csv-parse/lib/es5';
import firebase from 'services/firebase';
import moment from 'moment';

export const formatBytes = (a,b) => {if(0==a)return"0 Bytes";var c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]} //  eslint-disable-line

export const parseCSV = (str, {base64 = true, ...parseOptions} = {}) => {
    return import('xlsx')
    .then(xslx => {
        const base64Index = str.indexOf(';base64,');
        const trimIndex = base64Index === -1 ? 0 : base64Index + 8;
        const strToDecode = str.substr(trimIndex);
        const result = xslx.read(strToDecode, {cellText: false, cellDates: true});
        return xslx.utils.sheet_to_csv(result.Sheets[result.SheetNames[0]], {dateNF: 'DD"/"MM"/"YYYY'})
    })
    .then((result) => {
        return new Promise((resolve, reject) => {
            csvParse(result, parseOptions , (err, csvStr) => {
                if (err) {
                    return reject(err);
                }
                resolve(csvStr);
            });
        })
    })
}
export const createCSV = (data, options) => {
    return new Promise((resolve, reject) => {
        csvStringify(data, {...options, header: true} , (err, csvStr) => {
            if (err) {
                return reject(err);
            }
            resolve(csvStr);
        });
    })
}

export const htmlToText = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const prepareObjectToCsv = (csvObject) => {
    const reducedObject = Object.keys(csvObject).reduce((aggr, key) => {
        const value = csvObject[key];
        aggr[key] = value;

        let formattedDate;
        if (value instanceof firebase.firestore.Timestamp) {
            formattedDate = value.toDate();
        } else if (value instanceof Date) {
            formattedDate = value;
        }
        if (formattedDate) {
            aggr[`${key}Formatted`] = moment(formattedDate).format('DD/MM/YYYY, HH:mm:ss');
        }

        return aggr;
    }, {});
    return reducedObject;
};

export const formatDate = (date) => {
    if (!date) {
        return null;
    }
    return moment(date).format('DD/MM/YYYY');
}

export const formatDateTime = (date) => {
    if (!date) {
        return null;
    }
    return moment(date).format('DD/MM/YYYY, HH:mm:ss');
}

export function formatTemplate(template, formatDict) {
    if (!template) {
        return template;
    }

    const result = Object.keys(formatDict).reduce((aggr, key) => {
        return aggr.replace(new RegExp(`{{${key}}}`, 'g'), formatDict[key] || '')
    }, template);

    return result;
}