export default {
    firebaseConfig: {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
        appId: process.env.REACT_APP_APP_ID,
    },
    fcmVapidKey: process.env.REACT_APP_FCM_VAPID_PUBLIC_KEY,
    referrerKey: 'R_KEY',
    referrerOwnLinks: 'rl',
    SUPPORTED_IMAGES: 'image/jpg,image/jpeg,image/png',
    SUPPORTED_VIDEOS: 'video/mp4',
    SUPPORTED_FILES: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxLeadEmails: 3,
    applicantsPagination: 15,
    sharesPagination: 15,
    jobsPagination: 15,
    referrerPagination: 15,
    usersPagination: 15,
    apiKeysPagination: 15,
    notifsPagination: 15,
    viewedJobDelay: 5000,
    viewedJobsKey: 'vj',
    viewedJobsTimeout:  7 * 24 * 60 * 60 * 1000,
    heartbitInitialDelay: 5 * 1000,
    heartbitPollingTime: 5 * 60 * 1000,
    coverRatio: 8 / 3,
    coverMobileRatio: 3 / 2,
    idleThrottle: 5,
    sessionIdleExpiration: 60 * 20,
    defaultLocale: 'he',
    notif: {
        tokenResendTimeout: 5,
        permissionDelay: 5000,
        lastReqKey: 'lnr',
        sentToServerKey: 'nos',
        denyTimeout: 24 * 60 * 60 * 1000,
        reloadThrottleSeconds: 10 * 60,
    },
    previewsRatio: {
        wa: 1,
        fb: 1200 / 630,
    }
}