import {ActionTypes} from './apiKeysActions';
import {ActionTypes as ApiKeysListActionTypes} from 'containers/ApiKeysList/apiKeysListActions';

import config from 'config';

const mergeApiKeys = (state, apiKey) => {
    const apiKeys = (state.apiKeys || []).slice();
    const position = apiKeys.findIndex(c => c.id === apiKey.id);
    const newApiKeyPosition = position >= 0 ? position : apiKeys.length;
    apiKeys[newApiKeyPosition] = apiKey;
    return apiKeys;
}

const appendApiKeys = (state, apiKeysToMerge) => {
    const apiKeys = (state.apiKeys || []).concat(apiKeysToMerge || []);
    return apiKeys;
}

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.FETCH_API_KEYS_REQUEST:
        nextState = {...state, fetching: true, selectedKey: null};
        break;
    case ActionTypes.FETCH_MATCHING_API_KEY_REQUEST:
        nextState = {...state, fetching: true};
        break;
    case ActionTypes.FETCH_API_KEYS_SUCCESS: {
        const {apiKeys = [], last} = payload;
        nextState = {
            ...state,
            apiKeys, last,
            hasMore: apiKeys.length === config.apiKeysPagination,
            fetching: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_API_KEYS_SUCCESS: {
        const {apiKeys = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            apiKeys: appendApiKeys(state, apiKeys),
            last,
            hasMore: apiKeys.length === config.apiKeysPagination,
        };
        break;
    }
    case ActionTypes.FETCH_API_KEYS_FAILURE:
    case ActionTypes.FETCH_MATCHING_API_KEY_FAILURE:
        nextState = {...state, fetching: false};
        break;
    case ActionTypes.FETCH_MATCHING_API_KEY_SUCCESS: 
        nextState = {...state, fetching: false, apiKeys: mergeApiKeys(state, payload)};
        break;
    case ActionTypes.EDIT_API_KEY_REQUEST:
    case ActionTypes.ADD_API_KEY_REQUEST:
    case ActionTypes.DELETE_API_KEY_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.EDIT_API_KEY_SUCCESS:
    case ActionTypes.ADD_API_KEY_SUCCESS:
        nextState = {...state, loading: false, apiKeys: mergeApiKeys(state, payload), selectedKey: payload.id};
        break;
    case ActionTypes.EDIT_API_KEY_FAILURE:
    case ActionTypes.ADD_API_KEY_FAILURE:
    case ActionTypes.DELETE_API_KEY_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.DELETE_API_KEY_SUCCESS:
        nextState = {...state, loading: false, apiKeys: state.apiKeys.filter(u => u.id !== payload)};
        break;
    case ApiKeysListActionTypes.API_KEYS_LIST_ON_APIKEY_CLICK:
        nextState = {...state, selectedKey: payload.id}
        break;
    default:
        nextState = state;
    }
    return nextState;
};
