import {createSelector} from "reselect";
import { matchingCompanySelector } from "../companies/companiesSelectors";

export const sliceSelector = state => state.stats;
export const isLoadingSelector = createSelector(sliceSelector, slice => !!slice.loading);

export const statsSelector = createSelector(sliceSelector, matchingCompanySelector, (slice, company) => {
    const {stats} = slice;
    if (!stats) {
        return {};
    }
    const result = {...stats};
    const {numEmployees} = company || {};
    if (numEmployees) {
        const active = Math.round((result.referrers || 0) * 1000 / numEmployees) / 10;
        result.activeEmployees = `${active}%`;
    }
    const inactiveJobs = stats.totalJobs - stats.activeJobs;
    if (isNaN(inactiveJobs)) {
        return result;
    }
    return {...result, inactiveJobs: result.totalJobs - result.activeJobs};
});

