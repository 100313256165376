import {ActionTypes} from './customerActions';


export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {

    case ActionTypes.FETCH_CUSTOMER_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.FETCH_CUSTOMER_SUCCESS:
        nextState = {...state, loading: false, customer: payload};
        break;
    case ActionTypes.FETCH_CUSTOMER_FAILURE:
        nextState = {...state, loading: false, failure: true};
        break;
    case ActionTypes.EDIT_CUSTOMER_SETTINGS_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.FETCH_CUSTOMER_SETTINGS_REQUEST:
    case ActionTypes.EDIT_CUSTOMER_SETTINGS_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.FETCH_CUSTOMER_SETTINGS_SUCCESS:
    case ActionTypes.EDIT_CUSTOMER_SETTINGS_SUCCESS:
        nextState = {...state, loading: false, settings: payload};
        break;
    case ActionTypes.FETCH_CUSTOMER_SETTINGS_FAILURE:
        nextState = {...state, loading: false, settings: null};
        break;
    case ActionTypes.FETCH_CUSTOMER_TERMS_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.FETCH_CUSTOMER_TERMS_SUCCESS:
        nextState = {...state, loading: false, settings: {...state.settings, ...payload}};
        break;
    case ActionTypes.FETCH_CUSTOMER_TERMS_FAILURE:
        nextState = {...state, loading: false, settings: null};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
