function trackFacebookPixel(tracker) {
    if (!window.fbq) {
        return console.log('Missing fbq');
    }
    window.fbq(tracker.action, ...tracker.params);
}

export const getTracker = (actionKey) => {
    switch(actionKey) {
    case 'fbq':
        return trackFacebookPixel;
    default:
        throw new Error(`Invalid tracking key: ${actionKey}`);
    }
}