import {ActionTypes} from './companiesActions';

const mergeCompany = (state, company) => {
    const companies = (state.companies || []).slice();
    const position = companies.findIndex(c => c.id === company.id);
    const newCompanyPosition = position >= 0 ? position : companies.length;
    const oldCmp = companies[newCompanyPosition];
    companies[newCompanyPosition] = company;
    company.terms = company.terms || (oldCmp && oldCmp.terms);
    return companies;
}

const mergeTerms = (state, data) => {
    const companies = (state.companies || []).slice();
    const position = companies.findIndex(c => c.id === data.id);
    if (position === -1) {
        return state.companies;
    }
    companies[position] = {...companies[position], terms: data.terms};
    return companies;
}

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.FETCH_COMPANIES_SUCCESS:
        nextState = {...state, companies: payload};
        break;
    case ActionTypes.ADD_COMPANY_REQUEST:
        nextState = {...state, loading: true, justCreated: false};
        break;
    case ActionTypes.EDIT_COMPANY_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.ADD_COMPANY_SUCCESS:
        nextState = {...state, loading: false, companies: mergeCompany(state, payload), justCreated: true};
        break;
    case ActionTypes.EDIT_COMPANY_SUCCESS:
        nextState = {...state, loading: false, companies: mergeCompany(state, payload)};
        break;
    case ActionTypes.ADD_COMPANY_FAILURE:
    case ActionTypes.EDIT_COMPANY_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.FETCH_MATCHING_COMPANY_REQUEST: 
        nextState = {...state, fetching: true};
        break;
    case ActionTypes.FETCH_COMPANY_TERMS_SUCCESS: 
        nextState = {...state, companies: mergeTerms(state, payload)};
        break;
    case ActionTypes.FETCH_MATCHING_COMPANY_SUCCESS: 
        nextState = {...state, fetching: false, companies: mergeCompany(state, payload)};
        break;
    case ActionTypes.FETCH_MATCHING_COMPANY_FAILURE: 
        nextState = {...state, fetching: false};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
