import {Actions, ActionTypes} from 'modules/auth';
import config from 'config';
import {LOCATION_CHANGE} from 'react-router-redux';

let lastChangeTime = null;
let isThrottling = false;
let timeoutHandler = null;

const shouldLogout = (action, dispatch) => {
    if (action.type.startsWith('MFA_')) {
        return false;
    }

    if (action.type.startsWith('LOGIN_') && action.type !== ActionTypes.LOGIN_SUCCESS) {
        return false;
    }

    switch (action.type) {
    case LOCATION_CHANGE:
        return false;
    case ActionTypes.LOGIN_SUCCESS:
        lastChangeTime = Date.now();
        break;
    default:
        if (isThrottling) {
            return false;
        }
        isThrottling = true;
        setTimeout(() => { isThrottling = false; }, config.idleThrottle * 1000);
        break;
    }

    if (!lastChangeTime) {
        lastChangeTime = sessionStorage.getItem('lastChangeTime');
    }

    if (!lastChangeTime) {
        return true;
    }

    const now = Date.now();
    const diff = (now - lastChangeTime);
    if (diff > config.sessionIdleExpiration * 1000) {
        return true;
    }
    lastChangeTime = now;
    sessionStorage.setItem('lastChangeTime', now);

    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(() => dispatch(Actions.LOGOUT_ACTION()), config.sessionIdleExpiration * 1000);
    return false;
};

export default ({dispatch}) => next => action => {
    if (shouldLogout(action, dispatch)) {
        dispatch(Actions.LOGOUT_ACTION({reason: 'timeout'}));
    }
    const result = next(action);
    return result;
};
