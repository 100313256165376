import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_CUSTOMER_REQUEST',
    'FETCH_CUSTOMER_SUCCESS',
    'FETCH_CUSTOMER_FAILURE',
    'FETCH_CUSTOMER_ACTION',

    'FETCH_CUSTOMER_SETTINGS_REQUEST',
    'FETCH_CUSTOMER_SETTINGS_SUCCESS',
    'FETCH_CUSTOMER_SETTINGS_FAILURE',
    'FETCH_CUSTOMER_SETTINGS_ACTION',

    'FETCH_CUSTOMER_TERMS_REQUEST',
    'FETCH_CUSTOMER_TERMS_SUCCESS',
    'FETCH_CUSTOMER_TERMS_FAILURE',

    'EDIT_CUSTOMER_SETTINGS_REQUEST',
    'EDIT_CUSTOMER_SETTINGS_SUCCESS',
    'EDIT_CUSTOMER_SETTINGS_FAILURE',
    'EDIT_CUSTOMER_SETTINGS_ACTION',

    'PUBLIC_CUSTOMER_TERMS_MOUNTED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
