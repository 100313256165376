import firebase from 'services/firebase';
import {createActionTypes, createActionCreators} from 'utils/reduxUtils';
import queryString from 'query-string';

const typesArray = [
    'LOGIN_ACTION',
    'LOGIN_REQUEST',
    'LOGIN_SUCCESS',
    'LOGIN_FAILURE',

    'LOGOUT_ACTION',
    'LOGOUT_SUCCESS',

    'LOGIN_COMPANY_SELECTED',
    'LOGIN_PROVIDERS_REQUEST',
    'LOGIN_PROVIDERS_SUCCESS',
    'LOGIN_PROVIDERS_FAILURE',

    'ACTIVITY_DETECTED',

    'LOGIN_MFA_REQUIRED',
    'LOGIN_MFA_SETUP_REQUIRED',
    'MFA_GET_TOKEN_REQUEST',
    'MFA_GET_TOKEN_SUCCESS',
    'MFA_GET_TOKEN_FAILURE',

    'MFA_CODE_INSERTED',
    'MFA_SEND_CODE_REQUEST',
    'MFA_SEND_CODE_SUCCESS',
    'MFA_SEND_CODE_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);

export const fetchRelevantUserData = async (user) => {
    if (!user) {
        return null;
    }
    const {claims} = await user.getIdTokenResult();
    const {displayName, email, uid} = user;
    const {customerId, role, companyId, mfa} = claims;
    return {displayName, email, uid, customer: {id: customerId}, role, companyId, mfa};
}

export const watchAuthentication = (dispatch) => {
    return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(async function(user) {
            const updatedUser = await fetchRelevantUserData(user);
            if (!updatedUser) {
                const query = queryString.parse(window.location.search, {ignoreQueryPrefix: true})
                if (query.access_token) {
                    firebase.auth().signInWithCustomToken(query.access_token)
                    return;
                } else {
                    dispatch(Actions.LOGOUT_SUCCESS());
                }
            } else if (updatedUser.mfa === 'ns') {
                dispatch(Actions.LOGIN_MFA_SETUP_REQUIRED(updatedUser));
            } else if (updatedUser.mfa === 's') {
                dispatch(Actions.LOGIN_MFA_REQUIRED(updatedUser));
            } else {
                dispatch(Actions.LOGIN_SUCCESS(updatedUser));
            }
            resolve(updatedUser);
        });
    })
};
