import axios from 'services/axios';
import firebase from 'services/firebase';
import {put, call, takeLatest, select, take} from 'redux-saga/effects';
import {ActionTypes as AdminActionTypes} from 'containers/admin/adminActions';
import {ActionTypes as CompanyRouteActionTypes} from 'containers/CompanyRoute/companyRouteActions';
import {Selectors as AuthSelectors} from 'modules/auth';
import {Actions, ActionTypes as CompaniesActionTypes} from './companiesActions';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {ActionTypes as NotifAT} from 'modules/notifications/notifsActions';

function* addCompany(action) {
    yield put(Actions.ADD_COMPANY_REQUEST());
    try {
        const result = yield call(axios.post, 'customers/me/companies', action.payload);
        yield put(Actions.ADD_COMPANY_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ADD_COMPANY_FAILURE(err));
    }
}

function* updateCompany(action) {
    yield put(Actions.EDIT_COMPANY_REQUEST());
    const company = yield select(CompaniesSelectors.matchingCompanySelector);
    const companyId = company.id;
    try {
        yield call(axios.patch, `customers/me/companies/${companyId}`, action.payload);
        yield put(Actions.EDIT_COMPANY_SUCCESS({...company, ...action.payload}));
    } catch (err) {
        yield put(Actions.EDIT_COMPANY_FAILURE(err));
    }
}

const fetchSingleCompany = async (companyId) => {
    const comapnyDoc = await firebase.firestore().collection('companies').doc(companyId).get();
    const company = {...comapnyDoc.data(), id: comapnyDoc.id};
    return company;
}

const fetchCustomerCompanies = async (customerId) => {
    const comapniesQuery = await firebase.firestore()
    .collection('companies')
    .where('customerId', '==', customerId)
    .orderBy('name')
    .get();
    const companies = comapniesQuery.docs.map(d => ({...d.data(), id: d.id}));
    return companies;
}

function* fetchUserCompanies() {
    yield put(Actions.FETCH_COMPANIES_REQUEST());
    try {
        const limitingCompany = yield select(AuthSelectors.limitedToCompanySelector);
        let companies;
        if (limitingCompany) {
            const company = yield call(fetchSingleCompany, limitingCompany);
            companies = [company];
        } else {
            const customerId = yield select(AuthSelectors.customerIdSelector);
            companies = yield call(fetchCustomerCompanies, customerId);
        }
        
        yield put(Actions.FETCH_COMPANIES_SUCCESS(companies));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANIES_FAILURE(err));
    }
}

function* fetchMatchingCompany(action, fetchPrivate) {
    yield put(Actions.FETCH_MATCHING_COMPANY_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const promises = [firebase.firestore().collection('companies').doc(companyId).get()];
        if (fetchPrivate) {
            promises.push(firebase.firestore().collection('companies-private').doc(companyId).get());
        }
        const [publicDoc, privateDoc] = yield Promise.all(promises);
        if (!publicDoc.exists) {
            throw new Error(`Company ${companyId} doesn't exist`);
        }
        const company = {...publicDoc.data(), ...(privateDoc && privateDoc.data()), id: publicDoc.id};
        yield put(Actions.FETCH_MATCHING_COMPANY_SUCCESS(company));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_COMPANY_FAILURE(err));
    }
}

function* fetchCompanyTerms() {
    try {
        let company = yield select(CompaniesSelectors.matchingCompanySelector);
        if (!company) {
            const action = yield take(CompaniesActionTypes.FETCH_MATCHING_COMPANY_SUCCESS);
            company = action.payload;
        }
        if (!company.termsUrl) {
            return;
        }
        yield put(Actions.FETCH_COMPANY_TERMS_REQUEST());
        const response = yield fetch(company.termsUrl);
        const terms = yield response.text();
        yield put(Actions.FETCH_COMPANY_TERMS_SUCCESS({terms, id: company.id}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_TERMS_FAILURE(err));
    }
}

export default [
    takeLatest([AdminActionTypes.ADMIN_MOUNTING, CompaniesActionTypes.ADD_COMPANY_SUCCESS], fetchUserCompanies),
    takeLatest(CompaniesActionTypes.ADD_COMPANY_ACTION, addCompany),
    takeLatest(CompaniesActionTypes.EDIT_COMPANY_ACTION, updateCompany),
    takeLatest([
        CompanyRouteActionTypes.ADMIN_COMPANY_ROUTE_MOUNTING,
        NotifAT.UPDATE_NOTIFS_SETTINGS_SUCCESS,
    ], fetchMatchingCompany, true),
    takeLatest(CompanyRouteActionTypes.PUBLIC_COMPANY_ROUTE_MOUNTING, fetchMatchingCompany),
    takeLatest([
        CompaniesActionTypes.FETCH_COMPANY_TERMS_ACTION,
        CompaniesActionTypes.EDIT_COMPANY_MOUNTING
    ], fetchCompanyTerms),
]