import { createSelector } from 'reselect';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import {Selectors as CompaniesSelectors} from 'modules/companies';

export const sliceSelector = state => state.companyJobs;
export const companyJobsSelector = createSelector(sliceSelector, slice => slice.jobs || []);
export const isLoadingSelector = createSelector(sliceSelector, slice => slice.loading);
export const lastJobSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreJobsSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const wasJobJustCreatedSelector = createSelector(sliceSelector, slice => !!slice.created);
export const filterSelector = createSelector(sliceSelector, slice => slice.filter || {});
export const isLoadingFileSelector = createSelector(sliceSelector, slice => slice.loadingFile);

const getPropWithCompanyFallback = (jobId, job, company, prop) => {
    if (jobId && !job) {
        return null;
    }
    if (job && job[prop]) {
        return job[prop];
    }
    return company && company[prop];
}

export const matchingJobIdSelector = createSelector(
    NavigationSelectors.pathnameSelector, companyJobsSelector,
    (pathname, jobs) => {
        const matchedRegex = (pathname || '').match(/\/jobs\/([^/?]*)/) || [];
        const jobId = matchedRegex[1];
        return jobId;
    }
);

export const matchingJobSelector = createSelector(
    companyJobsSelector, matchingJobIdSelector,
    (jobs, jobId) => jobs.find(j => j.id === jobId),
);

export const jobCoverSelector = createSelector(
    matchingJobIdSelector, matchingJobSelector, CompaniesSelectors.matchingCompanySelector, 
    (jobId, job, company) => {
        return getPropWithCompanyFallback(jobId, job, company, 'cover');
    }
)

export const jobMobileCoverSelector = createSelector(
    matchingJobIdSelector, matchingJobSelector, CompaniesSelectors.matchingCompanySelector, 
    (jobId, job, company) => {
        return getPropWithCompanyFallback(jobId, job, company, 'coverMobile');
    }
)

export const jobIncentiveSelector = createSelector(
    matchingJobIdSelector, matchingJobSelector,
    CompaniesSelectors.matchingCompanySelector,
    NavigationSelectors.isReferrerViewSelector,
    (jobId, job, company, isReferrerView) => {

        const incentiveProp = isReferrerView ? 'rIncentive' : 'aIncentive';
        return getPropWithCompanyFallback(jobId, job, company, incentiveProp);
    }
);

export const referrerWorthSelector = createSelector(
    matchingJobIdSelector, matchingJobSelector, CompaniesSelectors.matchingCompanySelector, 
    (jobId, job, company) => {
        return getPropWithCompanyFallback(jobId, job, company, 'referrerWorth');
    }
);

export const referrerJobUrlSelector = createSelector(matchingJobIdSelector, CompaniesSelectors.matchingCompanyIdSelector,
    (jobId, companyId) => {
        return `/r/companies/${companyId}/jobs/${jobId}`;
    }
);

export const referrerJobFullUrlSelector = createSelector(
    NavigationSelectors.baseSelector,
    referrerJobUrlSelector,
    (baseUrl, referrerJobUrl) => {
        return `${baseUrl}${referrerJobUrl}`;
    }
);
