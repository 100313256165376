import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'SUBMIT_APPLICATION_FORM_ACTION',
    'SUBMIT_APPLICATION_FORM_REQUEST',
    'SUBMIT_APPLICATION_FORM_SUCCESS',
    'SUBMIT_APPLICATION_FORM_FAILURE',

    'FETCH_APPLICANTS_ACTION',
    'FETCH_APPLICANTS_REQUEST',
    'FETCH_APPLICANTS_SUCCESS',
    'FETCH_APPLICANTS_FAILURE',

    'FETCH_MORE_APPLICANTS_ACTION',
    'FETCH_MORE_APPLICANTS_REQUEST',
    'FETCH_MORE_APPLICANTS_SUCCESS',
    'FETCH_MORE_APPLICANTS_FAILURE',

    'FILTER_APPLICANTS_RESULTS',

    'APPLICANTS_UPDATE_APPLICANT',
    'APPLICANTS_UPDATE_APPLICANT_REQUEST',
    'APPLICANTS_UPDATE_APPLICANT_SUCCESS',
    'APPLICANTS_UPDATE_APPLICANT_FAILURE',

    'GENERATE_APPLICANTS_REPORT_ACTION',
    'GENERATE_APPLICANTS_REPORT_REQUEST',
    'GENERATE_APPLICANTS_REPORT_SUCCESS',
    'GENERATE_APPLICANTS_REPORT_FAILURE',

    'APPLICANTS_MODAL_CLOSING',

    'REFERRER_APPLICANTS_CLICK',
    'JOB_APPLICANTS_CLICK',
    'REFERRER_REVOKE_NOTIF_CLICK',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
