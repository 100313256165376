import axios from 'services/axios';
import firebase from 'services/firebase';
import {put, call, takeLatest, select} from 'redux-saga/effects';
import {Selectors as AuthSelectors} from 'modules/auth';
import {Actions, ActionTypes as ApiKeysActionTypes, Selectors as ApiKeysSelectors} from 'modules/apiKeys';
import config from 'config';

function* addApiKey(action) {
    yield put(Actions.ADD_API_KEY_REQUEST());
    try {
        const {name, enabled, companyId} = action.payload;
        const result = yield call(axios.post, 'customers/me/apiKeys', {name, enabled, companyId});
        yield put(Actions.ADD_API_KEY_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ADD_API_KEY_FAILURE(err));
    }
}

function* updateApiKey(action) {
    yield put(Actions.EDIT_API_KEY_REQUEST());
    const apiKey = yield select(ApiKeysSelectors.matchingApiKeySelector);
    try {
        const {name, enabled} = action.payload;
        const editApiKeyPayload = {};
        if (name) {editApiKeyPayload.name = name};
        if (enabled !== undefined) {editApiKeyPayload.enabled = enabled};
        yield call(axios.patch, `customers/me/apiKeys/${apiKey.id}`, editApiKeyPayload);
        yield put(Actions.EDIT_API_KEY_SUCCESS({...apiKey, ...editApiKeyPayload}));
    } catch (err) {
        yield put(Actions.EDIT_API_KEY_FAILURE(err));
    }
}

function* deleteApiKey(action) {
    yield put(Actions.DELETE_API_KEY_REQUEST());
    const {id} = action.payload;
    try {
        yield call(axios.delete, `customers/me/apiKeys/${id}`);
        yield put(Actions.DELETE_API_KEY_SUCCESS(id));
    } catch (err) {
        yield put(Actions.DELETE_API_KEY_FAILURE(err));
    }
}


const fetchApiKeysLogic = async (customerId, startAfter) => {
    let apiKeysQueryBuilder = firebase.firestore()
    .collection('api-keys')
    .where('customerId', '==', customerId)
    .limit(config.apiKeysPagination);

    if (startAfter) {
        apiKeysQueryBuilder = apiKeysQueryBuilder.startAfter(startAfter);
    }
    const query = await apiKeysQueryBuilder.get();
    const last = query.docs[query.docs.length - 1];
    const apiKeys = query.docs.map(d => ({...d.data(), id: d.id}));
    return {apiKeys, last};
}

function* fetchApiKeys(preventRequestAction) {
    if (!preventRequestAction) {
        yield put(Actions.FETCH_API_KEYS_REQUEST());
    }
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const {apiKeys, last} = yield call(fetchApiKeysLogic, customerId);
        yield put(Actions.FETCH_API_KEYS_SUCCESS({apiKeys, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_API_KEYS_FAILURE(err));
    }
}

function* fetchMoreApiKeys() {
    yield put(Actions.FETCH_MORE_API_KEYS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const lastSnap = yield select(ApiKeysSelectors.lastApiKeySelector);
        const {apiKeys, last} = yield call(fetchApiKeysLogic, customerId, lastSnap);
        yield put(Actions.FETCH_MORE_API_KEYS_SUCCESS({apiKeys, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_API_KEYS_FAILURE(err));
    }
}

function* fetchMatchingApiKey() {
    yield put(Actions.FETCH_MATCHING_API_KEY_REQUEST());
    try {
        const keyId = yield select(ApiKeysSelectors.matchingApiKeyIdSelector);
        const apiKeyDoc = yield firebase.firestore()
                                        .collection('api-keys')
                                        .doc(keyId)
                                        .get();
        const apiKey = {...apiKeyDoc.data(), id: apiKeyDoc.id};
        yield put(Actions.FETCH_MATCHING_API_KEY_SUCCESS(apiKey));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_API_KEY_FAILURE(err));
    }
}

export default [
    takeLatest(ApiKeysActionTypes.FETCH_API_KEYS_ACTION, fetchApiKeys, false),
    takeLatest(ApiKeysActionTypes.ADD_API_KEY_SUCCESS, fetchApiKeys, true),
    takeLatest(ApiKeysActionTypes.FETCH_MORE_API_KEYS_ACTION, fetchMoreApiKeys),
    takeLatest(ApiKeysActionTypes.ADD_API_KEY_ACTION, addApiKey),
    takeLatest(ApiKeysActionTypes.EDIT_API_KEY_ACTION, updateApiKey),
    takeLatest(ApiKeysActionTypes.DELETE_API_KEY_ACTION, deleteApiKey),
    takeLatest(ApiKeysActionTypes.FETCH_MATCHING_API_KEY_ACTION, fetchMatchingApiKey),
]