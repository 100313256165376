import {ActionTypes} from './companyJobsActions';
import config from 'config';

const mergeJobs = (state, job) => {
    const jobs = (state.jobs || []).slice();
    const position = jobs.findIndex(c => c.id === job.id);
    const newCompanyPosition = position >= 0 ? position : jobs.length;
    jobs[newCompanyPosition] = job;
    return jobs;
}

const appendJobs = (state, jobsToMerge) => {
    const jobs = (state.jobs || []).concat(jobsToMerge || []);
    return jobs;
}

const mergePrivateData = (state, payload) => {
    const {id, data} = payload;

    const jobs = (state.jobs || []).slice();
    const position = jobs.findIndex(c => c.id === id);
    if (position === -1) {
        return state;
    }
    jobs[position] = {...jobs[position], ...data};

    return {...state, jobs};
};

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.CREATE_JOB_REQUEST:
        nextState = {...state, loading: true, created: false};
        break;
    case ActionTypes.EDIT_JOB_REQUEST:
    case ActionTypes.FETCH_MATCHING_JOB_REQUEST:
    case ActionTypes.JOB_PUSH_REQUEST_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.CREATE_JOB_SUCCESS:
        nextState = {...state, loading: false, jobs: mergeJobs(state, payload), created: true};
        break;
    case ActionTypes.EDIT_JOB_SUCCESS:
    case ActionTypes.FETCH_MATCHING_JOB_SUCCESS:
    case ActionTypes.FETCH_JOB_BY_ID_SUCCESS:
        nextState = {...state, loading: false, jobs: mergeJobs(state, payload)};
        break;
    case ActionTypes.CREATE_JOB_FAILURE:
    case ActionTypes.EDIT_JOB_FAILURE:
    case ActionTypes.FETCH_MATCHING_JOB_FAILURE:
    case ActionTypes.JOB_PUSH_REQUEST_FAILURE:
    case ActionTypes.JOB_PUSH_REQUEST_SUCCESS:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.FETCH_COMPANY_JOBS_REQUEST:
        nextState = {...state, jobsForCompany: payload.companyId, loading: true};
        if (payload.companyId !== state.jobsForCompany) {
            nextState.jobs = null;
        }
        break;
    case ActionTypes.FETCH_COMPANY_JOBS_FAILURE:
        nextState = {...state, jobs: null, loading: false, fetchError: true};
        break;
    case ActionTypes.PRIVATE_JOB_FETCHED:
        nextState = mergePrivateData(state, payload);
        break;
    case ActionTypes.FETCH_COMPANY_JOBS_SUCCESS: {
        const {jobs = [], last} = payload;
        nextState = {
            ...state,
            jobs, last,
            hasMore: jobs.length === config.jobsPagination,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_JOBS_SUCCESS: {
        const {jobs = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            jobs: appendJobs(state, jobs),
            last,
            hasMore: jobs.length === config.jobsPagination,
        };
        break;
    }
    case ActionTypes.ADMIN_COMPANY_JOBS_COMPANY_CHANGED:
        nextState = {...state, filter: {}, last: null};
        break;
    case ActionTypes.FILTER_ADMIN_JOB_LIST:
        nextState = {...state, filter: payload, last: null};
        break;
    case ActionTypes.GENERATE_JOBS_REPORT_REQUEST:
        nextState = {...state, loadingFile: true};
        break;
    case ActionTypes.GENERATE_JOBS_REPORT_SUCCESS:
    case ActionTypes.GENERATE_JOBS_REPORT_FAILURE:
        nextState = {...state, loadingFile: false};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
