import queryString from 'query-string';
import {ActionTypes as AuthActionTypes, Selectors as AuthSelectors} from 'modules/auth';
import {push, replace, LOCATION_CHANGE} from 'react-router-redux';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import Config from 'config';
import {ActionTypes as UsersListActionTypes} from 'containers/adminUsersRoute/usersListRedux';
import {ActionTypes as ApplicantActionTypes} from 'modules/applicant';
import {ActionTypes as JobsActionTypes} from 'modules/companyJobs';
import {ActionTypes as CompaniesActionTypes, Selectors as CompanySelectors} from 'modules/companies';
import {ActionTypes as ReferrerActionTypes} from 'modules/referrer';
import {ActionTypes as NotifActionTypes} from 'modules/notifications';
import {ActionTypes as NotifModalActionTypes} from 'containers/createNotifModal/createNotifModalActions';

const handleLogin = ({getState, dispatch}) => {
    const pathname = NavigationSelectors.pathnameSelector(getState());
    const query = NavigationSelectors.querySelector(getState());
    if (query.return) {
        dispatch(replace(query.return));
    } else if (pathname === '/') {
        const companyId = AuthSelectors.limitedToCompanySelector(getState());
        const path = companyId ? `/admin/companies/${companyId}` : '/admin';
        dispatch(replace(path));
    }
}

const handleQueryUpdates = (store, action, companyId) => {
    if (!companyId) {
        return;
    }
    const {search = '', pathname} = action.payload;
    const parsedQuery = queryString.parse(search);
    const referrerByCompany = JSON.parse(localStorage.getItem(Config.referrerKey) || '{}');
    if (parsedQuery.r) {
        referrerByCompany[companyId] = parsedQuery.r;
        localStorage.setItem(Config.referrerKey, JSON.stringify(referrerByCompany));
    } else if (pathname.startsWith('/companies')) {
        const referrerKey = referrerByCompany[companyId];
        if (referrerKey) {
            parsedQuery.r = referrerKey;
            const updatedQuery = queryString.stringify(parsedQuery);
            const updatedPayload = {...action.payload, search: updatedQuery};
            store.dispatch(replace(updatedPayload));
        }
    }
}

export default store => next => action => {
    const result = next(action);

    const {payload, type, meta = {}} = action;

    switch (type) {
    case LOCATION_CHANGE:
        handleQueryUpdates(store, action, CompanySelectors.matchingCompanyIdSelector(store.getState()));
        break;
    case AuthActionTypes.LOGIN_SUCCESS:
        handleLogin(store);
        break;
    case AuthActionTypes.LOGIN_MFA_REQUIRED:
    case AuthActionTypes.LOGIN_MFA_SETUP_REQUIRED:
    case AuthActionTypes.LOGOUT_SUCCESS: {
        const pathname = NavigationSelectors.pathnameSelector(store.getState());
        if (pathname.startsWith('/admin')) {
            if (process.env.NODE_ENV === 'development') {
                store.dispatch(push(`/?return=${encodeURIComponent(pathname)}`));
            } else {
                store.dispatch(push(`/`));
            }
        }
    }
    break;
    case UsersListActionTypes.USER_LIST_ON_USER_CLICK:
        store.dispatch(push(`/admin/settings/users/${payload.uid}`));
        break;
    case ApplicantActionTypes.APPLICANTS_MODAL_CLOSING:
    case ApplicantActionTypes.REFERRER_APPLICANTS_CLICK:
    case ApplicantActionTypes.JOB_APPLICANTS_CLICK:
        store.dispatch(push(`/admin/applicants`));
        break;
    case ReferrerActionTypes.CREATE_EDIT_REEFERRER_MODAL_CLOSING: {
        const companyId = CompanySelectors.matchingCompanyIdSelector(store.getState());
        store.dispatch(push(`/admin/companies/${companyId}/referrers`));
        break;
    }
    case JobsActionTypes.CREATE_JOB_SUCCESS:
        const notifSuffix = meta.sendNotif ? '/notif' : '';
        const path = `/admin/companies/${payload.companyId}/jobs/${payload.id}${notifSuffix}`
        store.dispatch(push(path));
        break;
    case CompaniesActionTypes.ADD_COMPANY_SUCCESS:
        store.dispatch(push(`/admin/companies/${payload.id}`));
        break;
    case NotifModalActionTypes.CREATE_NOTIF_MODAL_CLOSED: {
        const companyId = CompanySelectors.matchingCompanyIdSelector(store.getState());
        store.dispatch(push(`/admin/companies/${companyId}/jobs`));
        break;
    }
    case NotifActionTypes.CREATE_JOB_NOTIF_SUCCESS: {
        const companyId = CompanySelectors.matchingCompanyIdSelector(store.getState());
        store.dispatch(push(`/admin/companies/${companyId}/notif-history`));
        break;
    }
    case AuthActionTypes.LOGIN_COMPANY_SELECTED: {
        if (!payload.skipNavigation) {
            store.dispatch(push(`?company=${payload.company}`));
        }
        break;
    }
    default:
        break;
    }
    return result;
}