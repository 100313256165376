import {ActionTypes} from './authActions';
import Messages from 'i18n/defaultMessages';

const getFailureMessage = (payload) => {
    switch (payload.code || payload.key) {
        case 'invalidCode':
            return Messages.mfa_invalidCodeError;
        case 'auth/user-not-found':
        case 'auth/wrong-password':
        case 'auth/invalid-email':
            return Messages.login_mismatch;
        default:
            return Messages.login_failed; 
    }
}
export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.MFA_SEND_CODE_REQUEST:
        nextState = {...state, loading: true, loginFailureMessage: false};
        break;
    case ActionTypes.MFA_GET_TOKEN_REQUEST:
        nextState = {...state, loading: true, screen: 'mfaScreen'};
        break;
    case ActionTypes.LOGIN_MFA_REQUIRED:
        nextState = {...state, loading: false, screen: 'mfaScreen'};
        break;
    case ActionTypes.LOGIN_SUCCESS:
        nextState = {...state, ...payload, isLoggedIn: true, loading: false};
        break;
    case ActionTypes.MFA_GET_TOKEN_SUCCESS:
        nextState = {...state, ...payload, isLoggedIn: false, loading: false};
        break;
    case ActionTypes.MFA_SEND_CODE_SUCCESS:
        nextState = {loading: false};
        break;
    case ActionTypes.LOGOUT_SUCCESS:
        nextState = {isLoggedIn: false};
        break;
    case ActionTypes.LOGIN_PROVIDERS_SUCCESS:
        nextState = {loginProviders: payload.items, loading: false};
        break;
    case ActionTypes.LOGIN_PROVIDERS_REQUEST:
        nextState = {loginProviders: null, loading: true};
        break;
    case ActionTypes.LOGIN_PROVIDERS_FAILURE:
        nextState = {loginProviders: null, loading: false};
        break;
    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.MFA_SEND_CODE_FAILURE:
        nextState = {isLoggedIn: false, loading: false, loginFailureMessage: getFailureMessage(payload), screen: state.screen};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
