import {ActionTypes} from './referrerActions';
import config from 'config';
import defaultMessages from 'i18n/defaultMessages';

const appendReferrers = (state, referrersToMerge) => {
    const referrers = (state.referrers || []).concat(referrersToMerge || []);
    return referrers;
}

const getLinks = () => {
    const rawLinks = localStorage.getItem(config.referrerOwnLinks) || '{}';
    const parsedLinks = JSON.parse(rawLinks);
    return parsedLinks;
}
const mergeLink = (state, payload) => {
    const {companyId, link, name, referrerId} = payload;
    const links = {...state.links};
    links[companyId] = {link, name, referrerId};
    localStorage.setItem(config.referrerOwnLinks, JSON.stringify(links));
    return links;
}

const mergeReferrer = (state, referrer, onTop) => {
    const referrers = (state.referrers || []).slice();
    const position = referrers.findIndex(c => c.id === referrer.id);
    if (position >= 0) {
        referrers[position] = referrer;
    } else if (onTop) {
        referrers.unshift(referrer);
    } else {
        referrers.push(referrer);
    }
    return referrers;
}

export default (state = {links: getLinks()}, action) => {
    let nextState;
    const {type, payload = {}} = action;
    switch (type) {
    case ActionTypes.CREATE_REFERRER_REQUEST:
        nextState = {...state, loadingReferrer: true, justCreated: false, error: null};
        break;
    case ActionTypes.CREATE_REFERRER_FAILURE:
        nextState = {...state, loadingReferrer: false};
        if (action.payload.response.status === 409) {
            nextState.error = defaultMessages.error_referrerAlreadyExists;
        }
        break;
    case ActionTypes.CREATE_REFERRER_SUCCESS:
        nextState = {
            ...state, loadingReferrer: false,
            justCreated: true, referrers: mergeReferrer(state, action.payload, true),
        };
        break;
    case ActionTypes.REFERRER_REVOKE_NOTIF_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.REFERRER_REVOKE_NOTIF_SUCCESS:
    case ActionTypes.REFERRER_REVOKE_NOTIF_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.GENERATE_LINK_REQUEST:
        nextState = {...state, loadingLink: true};
        break;
    case ActionTypes.GENERATE_LINK_FAILURE:
        nextState = {...state, loadingLink: false};
        break;
    case ActionTypes.GENERATE_LINK_SUCCESS:
        nextState = {...state, loadingLink: false, links: mergeLink(state, action.payload)};
        break;
    case ActionTypes.FETCH_SINGLE_REFERRER_SUCCESS:
        nextState = {...state, referrers: mergeReferrer(state, action.payload)};
        break;
    case ActionTypes.GENERATE_REFERRERS_REPORT_REQUEST:
        nextState = {...state, loadingFile: true};
        break;
    case ActionTypes.GENERATE_REFERRERS_REPORT_SUCCESS:
    case ActionTypes.GENERATE_REFERRERS_REPORT_FAILURE:
        nextState = {...state, loadingFile: false};
        break;
    case ActionTypes.FETCH_REFERRERS_ACTION:
        nextState = {...state, filter: null};
        break;
    case ActionTypes.FETCH_REFERRERS_REQUEST:
        nextState = {...state, referrers: null, loading: true, sorting: payload[0]};
        break;
    case ActionTypes.FETCH_REFERRERS_FAILURE:
        nextState = {...state, referrers: null, loading: false, fetchError: true};
        break;
    case ActionTypes.FETCH_REFERRERS_SUCCESS: {
        const {referrers = [], last} = payload;
        nextState = {
            ...state,
            referrers, last,
            hasMore: referrers.length === config.referrerPagination,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_REFERRERS_SUCCESS: {
        const {referrers = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            referrers: appendReferrers(state, referrers),
            last,
            hasMore: referrers.length === config.referrerPagination,
        };
        break;
    }
    case ActionTypes.IMPORT_REFERRERS_FROM_FILE_REQUEST:
        nextState = {...state, loadingImportFile: true};
        break;
    case ActionTypes.IMPORT_REFERRERS_FROM_FILE_SUCCESS:
        nextState = {...state, loadingImportFile: false};
        break;
    case ActionTypes.IMPORT_REFERRERS_FROM_FILE_FAILURE:
        nextState = {...state, loadingImportFile: false};
        break;
    case ActionTypes.IMPORT_REFERRERS_STATUS_UPDATED:
        nextState = {...state, importReferrersStatus: payload};
        break;
    case ActionTypes.FILTER_REFERRER_LIST:
        nextState = {...state, filter: payload};
        break;
    default:
    nextState = state;
    }
    return nextState;
};
