import { createSelector } from 'reselect';
import * as CompanySelectors from 'modules/companies/companiesSelectors';
import {Selectors as AuthSelectors} from 'modules/auth';
import {Selectors as NavigationSelectors} from 'modules/navigation';

export const sliceSelector = (state) => state.customer;
export const customerSelector = createSelector(sliceSelector, (slice) => slice.customer || {});
export const customerIdSelector = createSelector(customerSelector, (customer) => customer.id);
export const isLoadingSelector = createSelector(sliceSelector, (slice) => !!slice.loading);
export const customerSettingsSelector = createSelector(sliceSelector, (slice) => slice.settings || {});
export const customerFailureSelector = createSelector(sliceSelector, (slice) => slice.failure);

export const matchingCustomerIdSelector = createSelector(
    CompanySelectors.matchingCompanySelector, AuthSelectors.customerIdSelector, NavigationSelectors.pathnameSelector,
    (company, customerId, pathname) => {
        const matchedRegex = (pathname || '').match(/\/c\/([^/?]*)/) || [];
        const customerIdPath = matchedRegex[1];
        if (customerIdPath) {
            return customerIdPath;
        }
        if (company) {
            return company.customerId;
        }
        return customerId;
    }
);

export const termsUrlSelector = createSelector(customerIdSelector, customerId => `/c/${customerId}/terms`);
export const customerLogoSelector = createSelector(customerSelector, customer => customer.logo);
export const customerNameSelector = createSelector(customerSelector, customer => customer.name);
