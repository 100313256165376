import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [

    'FETCH_JOB_SHARES_ACTION',
    'FETCH_REFERRER_SHARES_ACTION',
    'FETCH_SHARES_REQUEST',
    'FETCH_SHARES_SUCCESS',
    'FETCH_SHARES_FAILURE',

    'FETCH_MORE_SHARES_ACTION',
    'FETCH_MORE_SHARES_REQUEST',
    'FETCH_MORE_SHARES_SUCCESS',
    'FETCH_MORE_SHARES_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
