import {put, all, select, takeLatest, fork} from 'redux-saga/effects';
import {ActionTypes, Actions, Selectors} from 'modules/shares';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {fetchReferrerById} from 'modules/referrer/referrerSagas';
import {fetchJobById} from 'modules/companyJobs/companyJobsSagas';
import firebase from 'services/firebase';
import config from 'config';

function* fetchSharesReferrers(shares, companyId) {
    const referrers = yield select(ReferrerSelectors.referrersSelector);
    const unique = shares.filter((share) => {
        const firstShareReferrerId = shares.find(inner => inner.referrerId === share.referrerId);
        return (firstShareReferrerId === share) && !referrers.find(r => r.id === share.referrerId);
    });
    yield all(unique.map(share => fork(fetchReferrerById, share.referrerId, companyId)));
}

function* fetchSharesJobs(shares, companyId) {
    const jobs = yield select(JobsSelectors.companyJobsSelector);
    const unique = shares.filter((share) => {
        const firstShareJobId = shares.filter(s => s.jobId).find(inner => inner.jobId === share.jobId);
        return (firstShareJobId === share) && !jobs.find(r => r.id === share.jobId);
    });
    yield all(unique.map(share => fork(fetchJobById, companyId, share.jobId)));
}

const fetchSharesLogic = async (companyId, filter, fetchAll) => {
    let queryBuilder = firebase.firestore()
    .collection('companies').doc(companyId).collection('shares')
    .orderBy('date', 'desc');

    if (!fetchAll) {
        queryBuilder = queryBuilder.limit(config.sharesPagination);
    }

    const {referrerId, jobId, shareId, startAfter} = filter;
    if (referrerId) {
        queryBuilder = queryBuilder.where('referrerId', '==', referrerId);
    }
    if (jobId) {
        queryBuilder = queryBuilder.where('jobId', '==', jobId);
    }
    if (shareId) {
        queryBuilder = queryBuilder.where('share', '==', shareId);
    }
    if (startAfter) {
        queryBuilder = queryBuilder.startAfter(startAfter);
    }
    const sharesQuery = await queryBuilder.get();
    const shares = sharesQuery.docs.map(d => ({...d.data(), id: d.id}));
    const last = sharesQuery.docs[sharesQuery.docs.length - 1];
    return {shares, last};
}

function* fetchShares(filter) {
    yield put(Actions.FETCH_SHARES_REQUEST(filter));
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const filter = yield select(Selectors.queryDataSelector);
        const {shares, last} = yield fetchSharesLogic(companyId, filter);
        yield fork(fetchSharesReferrers, shares, companyId);
        yield fork(fetchSharesJobs, shares, companyId);
        yield put(Actions.FETCH_SHARES_SUCCESS({shares, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_SHARES_FAILURE(err));
    }
}

function* fetchReferrerShares(action) {
    const {referrer, shareId} = action.payload;
    const {id: referrerId} = referrer;
    const filter = {referrerId, shareId};
    yield fork(fetchShares, filter);

}

function* fetchJobShares(action) {
    const {job, shareId} = action.payload;
    const {id: jobId} = job;
    const filter = {jobId, shareId};
    yield fork(fetchShares, filter);
}

function* fetchMoreShares() {
    yield put(Actions.FETCH_MORE_SHARES_REQUEST());
    try {
        const filter = yield select(Selectors.queryDataSelector);
        const lastSnap = yield select(Selectors.lastShareSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const paginatedFilter = lastSnap ? {...filter, startAfter: lastSnap} : filter;
        const {shares, last} = yield fetchSharesLogic(companyId, paginatedFilter);
        yield fork(fetchSharesReferrers, shares, companyId);
        yield fork(fetchSharesJobs, shares, companyId);
        yield put(Actions.FETCH_MORE_SHARES_SUCCESS({shares, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_SHARES_FAILURE(err));
    }
}

export default [
    takeLatest(ActionTypes.FETCH_JOB_SHARES_ACTION, fetchJobShares),
    takeLatest(ActionTypes.FETCH_REFERRER_SHARES_ACTION, fetchReferrerShares),
    takeLatest(ActionTypes.FETCH_MORE_SHARES_ACTION, fetchMoreShares),
];
