import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'ADMIN_LOCALE_CHANGED',
    'ADMIN_LOCALE_AUTO_SELECTED',
    'PUBLIC_LOCALE_AUTO_SELECTED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
