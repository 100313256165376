import { createSelector } from 'reselect';
import {Selectors as ReferrersSelectors} from 'modules/referrer';
import {Selectors as JobsSelectors} from 'modules/companyJobs';

export const sliceSelector = state => state.shares;
export const isLoadingSharesSelector = createSelector(sliceSelector, slice => slice.loadingShares);
export const sharesSelector = createSelector(sliceSelector, slice => slice.shares || []);
export const hasMoreSharesSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const lastShareSelector = createSelector(sliceSelector, slice => slice.last);
export const queryDataSelector = createSelector(sliceSelector, slice => slice.queryData || {});

export const sharesExtendedSelector = createSelector(
    sharesSelector, ReferrersSelectors.referrersSelector, JobsSelectors.companyJobsSelector,
    (shares, referrers, jobs) => {
        const mappedShares = shares.map(share => {
            const job = jobs.find(j => j.id === share.jobId);
            const referrer = referrers.find(r => r.id === share.referrerId);
            if (!job && !referrer) {
                return share;
            }
            return {
                ...share,
                jobTitle: (job || {}).title,
                referrerName: (referrer || {}).name,
            }
        });
        return mappedShares;  
    });
