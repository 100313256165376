import 'react-app-polyfill/ie11'; // For IE 11 support
import 'babel-polyfill';
import React from 'react';
import {render} from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store/store'
import AppRoutes from 'navigation/applicationRoutes'
import {unregister} from './registerServiceWorker';
import {watchAuthentication} from 'modules/auth';
import ReduxToastr from 'react-redux-toastr'
import HotLoader from 'i18n/HotLoader';
import ReactHint from 'components/Hint';

const target = document.querySelector('#root')

async function ensureIntlSupport() {
    if (window.Intl) {
        return;
    }
    await import('intl');
    await Promise.all([
      import('intl/locale-data/jsonp/en.js'),
      import('intl/locale-data/jsonp/he.js'),
    ]);
}

async function runApp() {
    await ensureIntlSupport()

    watchAuthentication(store.dispatch)
    .then(() => {
        render(
            <Provider store={store}>
                <HotLoader>
                    <div className="top-wrapper">
                        <ReactHint
                            persist
                            attribute="data-gen"
                            events
                            onRenderContent={(target) => {
                                return (<div className="react-hint__hint">{target.dataset.genTitle}</div>)
                            }}
                        />
                        <ConnectedRouter history={history}>
                                <AppRoutes />
                        </ConnectedRouter>
                        <ReduxToastr
                            position="top-left"
                            progressBar
                        />
                    </div>
                </HotLoader>
            </Provider>,
            target
        );
    })
}

runApp();
unregister();
