import firebase from 'services/firebase';
import {put, takeLatest, select, all} from 'redux-saga/effects';
import {Selectors as AuthSelectors} from 'modules/auth';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Actions, ActionTypes as StatsActionTypes} from 'modules/stats';

const fetchLatestApplicants = (customerId, companyId) => {
    let queryBuilder = firebase.firestore()
        .collection('customers')
        .doc(customerId)
        .collection('applicants')
        .orderBy('created', 'desc')
        .limit(5);

    if (companyId) {
        queryBuilder = queryBuilder.where('companyId', '==', companyId);
    }
    return queryBuilder.get().then(applicantsSnap => {
        return applicantsSnap.docs.map(d => ({id: d.id, ...d.data()}))
    });
}

const fetchAndMergeJob = (privateJob) => {
    return firebase.firestore().collection('jobs').doc(privateJob.id).get()
    .then(jobSnap => ({...privateJob, ...jobSnap.data()}))
}

const fetchTopJobs = (customerId, companyId) => {
    let queryBuilder = firebase.firestore()
        .collection('jobs-private')
        .limit(5)        
        .where('customerId', '==', customerId);
    if (companyId) {
        queryBuilder = queryBuilder.where('companyId', '==', companyId);
    }
    return queryBuilder
        .orderBy('viewed', 'desc')
        .get()
        .then(privateJobsSnap => {
        return privateJobsSnap.docs.map(d => ({id: d.id, ...d.data()}))
    })
    .then((privateJobs) => {
        const topViewed = privateJobs.map(fetchAndMergeJob);
        return Promise.all(topViewed)
    })
}

function* fetchStats() {
    yield put(Actions.FETCH_STATS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);        
        const [statDoc, mostViewed, latestApplicants] = yield all([
            firebase.firestore().collection('stats').doc(customerId).get(),
            fetchTopJobs(customerId),
            fetchLatestApplicants(customerId),
        ]);
        const stats = {...statDoc.data(), mostViewed, latestApplicants};
        yield put(Actions.FETCH_STATS_SUCCESS(stats));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_STATS_FAILURE(err));
    }
}

function* fetchCompanyStats() {
    yield put(Actions.FETCH_COMPANY_STATS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const [statDoc, mostViewed, latestApplicants] = yield all([
            firebase.firestore().collection('stats').doc(customerId).collection('cmp-stats').doc(companyId).get(),
            fetchTopJobs(customerId, companyId),
            fetchLatestApplicants(customerId, companyId),
        ]);
        const stats = {...statDoc.data(), mostViewed, latestApplicants};
        yield put(Actions.FETCH_COMPANY_STATS_SUCCESS(stats));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_STATS_FAILURE(err));
    }
}

export default [
    takeLatest(StatsActionTypes.FETCH_STATS_ACTION, fetchStats),
    takeLatest(StatsActionTypes.FETCH_COMPANY_STATS_ACTION, fetchCompanyStats),
]