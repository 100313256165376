import firebase from 'services/firebase';
import {delay, eventChannel, buffers} from 'redux-saga';
import {put, call, takeLatest, select, race, fork, take, cancel} from 'redux-saga/effects';
import {ActionTypes as AuthActionTypes, Actions as AuthActions, Selectors as AuthSelectors, fetchRelevantUserData} from 'modules/auth';
import axios from 'services/axios';
import config from 'config';

export function* heartbit() {
    try {
        yield delay(config.heartbitInitialDelay);
        yield call(axios.get, 'ping');
        while (true) {
            yield delay(config.heartbitPollingTime);
            yield call(axios.get, 'ping');
            yield call(axios.get, window.location.origin);
        }
    } catch (err) {
        console.log(err);
    }
}

export function* login(action) {
    const {user, password} = action.payload;
    yield put(AuthActions.LOGIN_REQUEST());
    try {
        yield firebase.auth().signInWithEmailAndPassword(user, password);
        yield call(axios.post, 'login');
    } catch (err) {
        yield put(AuthActions.LOGIN_FAILURE(err));
        yield call(axios.post, 'loginFailure', {user, errorCode: err.code});
    }
};

export function* logout(action) {
    const isLoggedIn = yield select(AuthSelectors.isLoggedInSelector);
    if (isLoggedIn) {
        yield race({
            action: call(axios.post, 'logout', action.payload),
            timeout: delay(20)
        });
    }
    yield firebase.auth().signOut();
};

function* updateOnMouseOrKeyboardTouch() {
    const channel = eventChannel(emit => {
        document.addEventListener('click', emit);
        document.addEventListener('keydown', emit);
        return () => {
            document.removeEventListener('click', emit);
            document.removeEventListener('keydown', emit);
        }
    }, buffers.dropping(1));
    try {
        while(true) {
            yield delay(config.idleThrottle * 1000);
            yield take(channel);
            yield put(AuthActions.ACTIVITY_DETECTED());
        }
    } finally {
        channel.close();
    }
}

function* initiateMFAFlow() {
    yield put(AuthActions.MFA_GET_TOKEN_REQUEST());
    try {
        const {data} = yield call(axios.post, '/mfa/generateToken');
        yield put(AuthActions.MFA_GET_TOKEN_SUCCESS(data));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.MFA_GET_TOKEN_FAILURE(err));
    }
}

function* validateMFACode(action) {
    yield put(AuthActions.MFA_SEND_CODE_REQUEST());
    try {
        const {code} = action.payload;
        const {data} = yield call(axios.post, '/mfa/verifyCode', {code});
        const signInInfo = yield call(() =>firebase.auth().signInWithCustomToken(data.token));
        const userInfo = yield call(() => fetchRelevantUserData(signInInfo.user));
        yield put(AuthActions.MFA_SEND_CODE_SUCCESS(data));
        yield put(AuthActions.LOGIN_SUCCESS(userInfo));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.MFA_SEND_CODE_FAILURE(err.response ? err.response.data : err));
    }
}

export function* monitorActivity() {
    const task = yield fork(updateOnMouseOrKeyboardTouch);
    yield take(AuthActionTypes.LOGOUT_SUCCESS);
    yield cancel(task);
}

function* fetchCompanyProviders(action) {
    yield put(AuthActions.LOGIN_PROVIDERS_REQUEST());
    try {
        const {company} = action.payload;
        const {data} = yield call(axios.get, `/companies/${company}/loginProviders`);
        yield put(AuthActions.LOGIN_PROVIDERS_SUCCESS(data));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.LOGIN_PROVIDERS_FAILURE(err));
    }
}

export default [
    takeLatest(AuthActionTypes.LOGIN_ACTION, login),
    takeLatest(AuthActionTypes.LOGOUT_ACTION, logout),
    takeLatest([AuthActionTypes.LOGOUT_SUCCESS, AuthActionTypes.LOGIN_SUCCESS], heartbit),
    takeLatest(AuthActionTypes.LOGIN_SUCCESS, monitorActivity),
    takeLatest(AuthActionTypes.LOGIN_MFA_SETUP_REQUIRED, initiateMFAFlow),
    takeLatest(AuthActionTypes.MFA_CODE_INSERTED, validateMFACode),
    takeLatest(AuthActionTypes.LOGIN_COMPANY_SELECTED, fetchCompanyProviders),
];
