import {ActionTypes} from './notifsActions';
import config from 'config';

const appendHistory = (state, data) => {
    const history = (state.history || []).concat(data || []);
    return history;
}

const defaultState = {
    notifStat: window.Notification && window.Notification.permission,
    showPermissionModal: false,
}
export default (state = defaultState, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.FETCH_NOTIFS_SETTINGS_REQUEST:
    case ActionTypes.UPDATE_NOTIFS_SETTINGS_REQUEST:
    case ActionTypes.FETCH_MORE_NOTIF_HISTORY_REQUEST:
    case ActionTypes.FETCH_NOTIF_HISTORY_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.FETCH_NOTIF_HISTORY_SUCCESS: {
        const {history = [], last} = payload;
        nextState = {
            ...state,
            history, lastHistory: last,
            hasMoreHistory: history.length === config.notifsPagination,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_NOTIF_HISTORY_SUCCESS: {
        const {history = [], last} = payload;
        nextState = {
            ...state,
            loading: false,
            history: appendHistory(state, history),
            lastHistory: last,
            hasMoreHistory: history.length === config.notifsPagination,
        };
        break;
    }
    case ActionTypes.CREATE_JOB_NOTIF_REQUEST:
        nextState = {...state, loading: true, justCreated: false};
        break;
    case ActionTypes.CREATE_JOB_NOTIF_SUCCESS:
        nextState = {...state, loading: false, justCreated: true};
        break;
    case ActionTypes.UPDATE_NOTIFS_SETTINGS_SUCCESS: {
        nextState = {
            ...state,
            settings: payload.data,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_NOTIFS_SETTINGS_SUCCESS: {
        const {settings} = payload;
        nextState = {
            ...state,
            settings,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_NOTIFS_SETTINGS_FAILURE:
    case ActionTypes.UPDATE_NOTIFS_SETTINGS_FAILURE:
    case ActionTypes.FETCH_MORE_NOTIF_HISTORY_FAILURE:
    case ActionTypes.FETCH_NOTIF_HISTORY_FAILURE:
    case ActionTypes.CREATE_JOB_NOTIF_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.HIDE_NOTIF_PERMISSION_MODAL:
        nextState = {...state, showPermissionModal: false};
        break;
    case ActionTypes.SHOW_NOTIF_PERMISSION_MODAL:
        nextState = {...state, showPermissionModal: true};
        break;
    case ActionTypes.NOTIF_PERMISSION_CHANGED:
        nextState = {...state, notifStat: payload.state};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
