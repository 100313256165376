import axios from 'services/axios';
import {delay, eventChannel, buffers} from 'redux-saga';
import {takeLeading} from 'utils/reduxUtils';
import {put, call, select, takeLatest, take, cancel, cancelled, fork} from 'redux-saga/effects';
import {ActionTypes as ReferrerActionTypes, Actions as ReferrerActions, Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import {Selectors as NotifSelectors} from 'modules/notifications';
import {ActionTypes as ReferrerListAT} from 'containers/ReferrersList/referrerListActions';
import firebase from 'services/firebase';
import config from 'config';
import fileSaver from 'file-saver';
import { createCSV, prepareObjectToCsv } from 'utils/transformUtils';
import moment from 'moment';

const formattedDate = (key, date) => {
    if (key === 'birthday') {
        return date.format('YYYY-MM-DD');
    }
    return date.toDate();
};

const mapReferrer = (doc) => {
    const mapped = {id: doc.id, ...doc.data()};
    if (mapped.created) {
        mapped.created = mapped.created.toDate();
    }
    return mapped;
}
const fetchReferrersLogic = async (companyId, {sorting, startAfter, fetchAll, filter = {}} = {}) => {
    let queryBuilder = await firebase.firestore()
    .collection('companies')
    .doc(companyId)
    .collection('referrers')

    if (filter.id) {
        const query = await queryBuilder.doc(filter.id).get();
        return {referrers: [{...query.data(), id: query.id}]}
    }

    if (!fetchAll) {
        queryBuilder = queryBuilder.limit(config.referrerPagination);
    }

    let finalSorting = sorting;

    const simpleFilters = ['internalId', 'name', 'email', 'comment', 'comment2', 'comment3'];
    simpleFilters.forEach(key => {
        if (filter[key]) {
            queryBuilder = queryBuilder.where(key, '==', filter[key]);
            finalSorting = null;
        }
    });

    ['section', 'gdpr'].forEach(key => {
        if (filter[key] || typeof filter[key] === 'boolean') {
            queryBuilder = queryBuilder.where(key, '==', filter[key]);
        }
    });

    const key = filter.dateField && ['created', 'lastActive', 'birthday'].find(key => filter.dateField === key);
    if (key) {
        const {startDate, endDate} = filter.date || {};
        finalSorting = {id: key, desc: true};

        const formattedStartDate = formattedDate(key, startDate || moment('1900-01-01'));
        queryBuilder = queryBuilder.where(key, '>=', formattedStartDate);

        const formattedEndDate = formattedDate(key, endDate || moment());
        queryBuilder = queryBuilder.where(key, '<=', formattedEndDate);
    }

    if (finalSorting) {
        queryBuilder = queryBuilder.orderBy(finalSorting.id, finalSorting.desc ? 'desc' : 'asc');
    }

    if (startAfter) {
        queryBuilder = queryBuilder.startAfter(startAfter);
    }

    const query = await queryBuilder.get();
    const referrers = query.docs.map(mapReferrer);
    const last = query.docs[query.docs.length - 1];
    return {referrers, last};
}

export const fetchReferrerByIdLogic = async (referrerId, companyId) => {
    const referrerDoc = await firebase.firestore()
        .collection('companies')
        .doc(companyId)
        .collection('referrers')
        .doc(referrerId)
        .get();
    const referrer = mapReferrer(referrerDoc);
    return referrer;
}

export function* fetchReferrerById(referrerId, companyId) {
    yield put(ReferrerActions.FETCH_SINGLE_REFERRER_REQUEST(referrerId));
    try {
        const referrer = yield fetchReferrerByIdLogic(referrerId, companyId);
        yield put(ReferrerActions.FETCH_SINGLE_REFERRER_SUCCESS(referrer));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_SINGLE_REFERRER_FAILURE(err));
    }
}

function* fetchReferrers(action) {
    yield put(ReferrerActions.FETCH_REFERRERS_REQUEST(action.payload));
    try {
        const filter = yield select(ReferrerSelectors.filterSelector);
        const sorting = yield select(ReferrerSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {referrers, last} = yield fetchReferrersLogic(companyId, {sorting, filter});
        yield put(ReferrerActions.FETCH_REFERRERS_SUCCESS({referrers, last}));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_REFERRERS_FAILURE(err));
    }
}

function* fetchMoreReferrers(action) {
    yield put(ReferrerActions.FETCH_MORE_REFERRERS_REQUEST());
    try {
        const sorting = yield select(ReferrerSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const startAfter = yield select(ReferrerSelectors.lastReferrerSelector);
        const filter = yield select(ReferrerSelectors.filterSelector);
        const {referrers, last} = yield fetchReferrersLogic(companyId, {sorting, filter, startAfter});
        yield put(ReferrerActions.FETCH_MORE_REFERRERS_SUCCESS({referrers, last}));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_MORE_REFERRERS_FAILURE(err));
    }
}

export function* generateLink(action) {
    yield put(ReferrerActions.GENERATE_LINK_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const jobId = yield select(JobsSelectors.matchingJobIdSelector);
        const isNotifPermissionGranted = yield select(NotifSelectors.permissionGranted);
        let token = null;
        if (isNotifPermissionGranted) {
            token = yield firebase.messaging().getToken();
        }
        const url = `companies/${companyId}/jobs/generateLink`;
        const result = yield call(axios.post, url, {...action.payload, jobId, token});
        
        const {link} = result.data;
        yield put(ReferrerActions.GENERATE_LINK_SUCCESS({link, companyId, ...action.payload}));
    } catch (err) {
        console.error(err);
        yield put(ReferrerActions.GENERATE_LINK_FAILURE(err));
    }
};

export function* generateReferrersReport(action) {
    yield put(ReferrerActions.GENERATE_REFERRERS_REPORT_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const sorting = yield select(ReferrerSelectors.sortingSelector);
        const filter = yield select(ReferrerSelectors.filterSelector);

        const columns = {
            createdFormatted: 'Created', name: 'name', id: 'phone',
            internalId: 'Id', email: 'email', section: 'section',
            birthday: 'birthday', applicants: 'applicants', views: 'views', gdpr: 'Accept Terms',
            fb: 'facebook shares', ln: 'linkedin shares', wa: 'whatsapp shares', 'url': 'copy shares',
            comment: 'field 1', comment2: 'field 2', comment3: 'field 3',
        };
        const {referrers} = yield fetchReferrersLogic(companyId, {sorting, filter, fetchAll: true});
        const preparedReferrers = referrers.map(prepareObjectToCsv);
        const csv = yield createCSV(preparedReferrers, {columns});
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
        yield put(ReferrerActions.GENERATE_REFERRERS_REPORT_SUCCESS());
        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `referrers-${currentDate}.csv`);
    } catch (err) {
        console.error(err);
        yield put(ReferrerActions.GENERATE_REFERRERS_REPORT_FAILURE(err));
    }
}

export function* logReferrerShare(action) {
    // if (window.Notification) {
    //     const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    //     console.log(companyId);
    //     var result = yield call(window.Notification.requestPermission);
    //     console.log(result);
    //     window.firebase.messaging().getToken().then(async token => {
    //         console.log(token);
    //         await axios.post(`companies/${companyId}/notifications/register`, {token});

    //     });
    //     // var token = yield call();
    //     // console.log(token);
    //     return;
    // }
    const isReferrerView = yield select(NavigationSelectors.isReferrerViewSelector);
    const referrerSelector = isReferrerView ? ReferrerSelectors.referrerOwnLinkSelector : ReferrerSelectors.matchingReferrerLinkSelector;
    const referrerLink = yield select(referrerSelector);
    if (!referrerLink) {
        return;
    }
    const jobId = yield select(JobsSelectors.matchingJobIdSelector);
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    try {
        const payload = {jobId, isReshare: !isReferrerView, type: action.payload};
        yield call(axios.post, `companies/${companyId}/referrers/${referrerLink}/share`, payload);
        yield delay(1000);
    } catch (err) {
        console.log(err);
    }
}

export function createEventChannel(ref) {
    const listener = eventChannel(emit => {
        const unsubscribe = ref.onSnapshot(snap => {
            emit(snap);
        })
        return () => {
            unsubscribe();
        }
    }, buffers.expanding(1));
    return listener;
}


function* updateOnImportReferrersStatusChange() {
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    const ref = firebase.firestore()
        .collection('companies').doc(companyId)
        .collection('settings-private').doc('importReferrers');
    const chan = yield call(createEventChannel, ref);
    try {
        while(true) {
            const snap = yield take(chan);
            yield put(ReferrerActions.IMPORT_REFERRERS_STATUS_UPDATED(snap.data()))
        }
    } finally {
        if (yield cancelled()) {
            chan.close();
        }
    }
}

function* monitorImportReferrersStatus() {
    const task = yield fork(updateOnImportReferrersStatusChange);
    yield take(ReferrerActionTypes.IMPORT_REFERRERS_FROM_FILE_UNMOUNTED);
    yield cancel(task);
}

export function* importReferrersFromFile(action) {
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    yield put(ReferrerActions.IMPORT_REFERRERS_FROM_FILE_REQUEST());
    try {
        yield call(axios.post, `customers/me/companies/${companyId}/referrers/import`, action.payload);
        yield put(ReferrerActions.IMPORT_REFERRERS_FROM_FILE_SUCCESS());
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.IMPORT_REFERRERS_FROM_FILE_FAILURE(err));
    }
}

function* createNewReferrer(action) {
    yield put(ReferrerActions.CREATE_REFERRER_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const newReferrer = {...action.payload};
        const result = yield call(axios.post, `customers/me/companies/${companyId}/referrers`, newReferrer);
        yield put(ReferrerActions.CREATE_REFERRER_SUCCESS(result.data));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.CREATE_REFERRER_FAILURE(err));
    }
}

function* revokeReferrerTokens(action) {
    yield put(ReferrerActions.REFERRER_REVOKE_NOTIF_REQUEST());
    try {
        const {id: referrerId} = action.payload;
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const result = yield call(axios.post, `customers/me/companies/${companyId}/referrers/${referrerId}/revokeNotif`);
        yield put(ReferrerActions.REFERRER_REVOKE_NOTIF_SUCCESS(result.data));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.REFERRER_REVOKE_NOTIF_FAILURE(err));
    }
}

export default [
    takeLeading(ReferrerActionTypes.LINK_IS_SHARED, logReferrerShare),
    takeLatest(ReferrerActionTypes.GENERATE_LINK_ACTION, generateLink),
    takeLatest([
        ReferrerActionTypes.FETCH_REFERRERS_ACTION,
        ReferrerActionTypes.FILTER_REFERRER_LIST,
    ], fetchReferrers),
    takeLatest(ReferrerActionTypes.FETCH_MORE_REFERRERS_ACTION, fetchMoreReferrers),
    takeLatest(ReferrerActionTypes.GENERATE_REFERRERS_REPORT, generateReferrersReport),
    takeLatest(ReferrerActionTypes.IMPORT_REFERRERS_FROM_FILE_ACTION, importReferrersFromFile),
    takeLatest(ReferrerActionTypes.IMPORT_REFERRERS_FROM_FILE_MOUNTED, monitorImportReferrersStatus),
    takeLatest(ReferrerActionTypes.CREATE_REFERRER_ACTION, createNewReferrer),
    takeLatest(ReferrerListAT.REF_LIST_REVOKE_NOTIF_CLICK, revokeReferrerTokens),
];
