import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_API_KEYS_ACTION',
    'FETCH_API_KEYS_REQUEST',
    'FETCH_API_KEYS_SUCCESS',
    'FETCH_API_KEYS_FAILURE',

    'FETCH_MORE_API_KEYS_ACTION',
    'FETCH_MORE_API_KEYS_REQUEST',
    'FETCH_MORE_API_KEYS_SUCCESS',
    'FETCH_MORE_API_KEYS_FAILURE',

    'ADD_API_KEY_ACTION',
    'ADD_API_KEY_REQUEST',
    'ADD_API_KEY_SUCCESS',
    'ADD_API_KEY_FAILURE',

    'EDIT_API_KEY_ACTION',
    'EDIT_API_KEY_REQUEST',
    'EDIT_API_KEY_SUCCESS',
    'EDIT_API_KEY_FAILURE',

    'FETCH_MATCHING_API_KEY_ACTION',
    'FETCH_MATCHING_API_KEY_REQUEST',
    'FETCH_MATCHING_API_KEY_SUCCESS',
    'FETCH_MATCHING_API_KEY_FAILURE',

    'DELETE_API_KEY_ACTION',
    'DELETE_API_KEY_REQUEST',
    'DELETE_API_KEY_SUCCESS',
    'DELETE_API_KEY_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
