import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_NOTIFS_SETTINGS_REQUEST',
    'FETCH_NOTIFS_SETTINGS_SUCCESS',
    'FETCH_NOTIFS_SETTINGS_FAILURE',

    'UPDATE_NOTIFS_SETTINGS_REQUEST',
    'UPDATE_NOTIFS_SETTINGS_SUCCESS',
    'UPDATE_NOTIFS_SETTINGS_FAILURE',

    'CREATE_JOB_NOTIF_REQUEST',
    'CREATE_JOB_NOTIF_SUCCESS',
    'CREATE_JOB_NOTIF_FAILURE',

    'FETCH_NOTIF_HISTORY_REQUEST',
    'FETCH_NOTIF_HISTORY_SUCCESS',
    'FETCH_NOTIF_HISTORY_FAILURE',
    
    'FETCH_MORE_NOTIF_HISTORY_REQUEST',
    'FETCH_MORE_NOTIF_HISTORY_SUCCESS',
    'FETCH_MORE_NOTIF_HISTORY_FAILURE',

    'HIDE_NOTIF_PERMISSION_MODAL',
    'SHOW_NOTIF_PERMISSION_MODAL',

    'NOTIF_PERMISSION_CHANGED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
