import { createSelector } from 'reselect';
import {Selectors as NavigationSelectors} from 'modules/navigation';

export const companiesSelector = (state) => state.companies.companies || [];
export const isLoadingSelector = state => !!state.companies.loading;
export const wasJustCreatedSelector = state => !!state.companies.justCreated;
export const isFetchingDataSelector = state => !!state.companies.fetching;

export const matchingCompanyIdSelector = createSelector(
    NavigationSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/companies\/([^/?]*)/) || [];
        const companyId = matchedRegex[1];
        return companyId;
    }
);

export const matchingCompanySelector = createSelector(
    matchingCompanyIdSelector, companiesSelector,
(matchingCompanyId, companies) => {
        return companies.find(c => c.id === matchingCompanyId);
    }
);

export const referrerCompanyUrlSelector = createSelector(matchingCompanyIdSelector,
    (companyId) => {
        return `/r/companies/${companyId}/jobs`;
    }
);

export const termsUrlSelector = createSelector(matchingCompanyIdSelector, NavigationSelectors.isReferrerViewSelector,
    (companyId, isReffererView) => {
        const termsUrl =  `/companies/${companyId}/terms`;
        if (isReffererView) {
            return `/r${termsUrl}`;
        }
        return termsUrl;

    }
)

export const notifEnabledSelector = createSelector(matchingCompanySelector, (cmp) => {
    return !!(cmp && cmp.notifEnabled && cmp.notif && cmp.notif.amountLeft > 0);
});

export const publicNotifEnabledSelector = createSelector(matchingCompanySelector, (cmp) => {
    return !!(cmp && cmp.notifEnabled);
});

export const notifAmountSelector = createSelector(matchingCompanySelector, (cmp) => {
        if (cmp.notifEnabled && cmp.notif) {
            return cmp.notif.amountLeft
        }
        return false;
    }
);
