import React, {Component} from 'react';
import Messages from 'i18n/defaultMessages';
import SimpleTable from 'components/SimpleTable';
import Loader from 'components/Loader';
import ElevatedBox from 'components/ElevatedBox';
import ConfirmationModal from 'components/ConfirmationModal';

export default class UsersList extends Component {
    state = {showDeleteModal: false}
    componentDidMount() {
        this.props.onMounting();
    }

    getColumnHeader = (name) => {
        const {intl} = this.props;
        const title = intl.formatMessage(Messages[`userList_header_${name}`])
        return {title, id: name};
    }

    closeModal = () => {
        this.setState({showDeleteModal: false, user: null});        
    }

    handleUserDelete = () => {
        const {user} = this.state;
        this.props.onDeleteUser(user);
        this.closeModal();
    }

    renderMfaColumn = (data) => {
        return data.mfa ? <i className="fas fa-key mfa-icon" /> : '';
    }

    renderTable = () => {
        const {onUserClick, hasMore, loadMore} = this.props;
        const columns = [
            {...this.getColumnHeader('mfa'), accessor: this.renderMfaColumn, width: 70},
            this.getColumnHeader('displayName'),
            this.getColumnHeader('email'),
            this.getColumnHeader('companyName'),
        ];

    return (
        <SimpleTable
                onRowClick={onUserClick}
                showDelete
                onRowDelete={user => this.setState({showDeleteModal: true, user})}
                columns={columns}
                rows={this.props.users}
                onSortingChange={() => {}}
                hasMore={hasMore}
                loadMore={loadMore}
            />
        );
    }
    render = () => {
        const {loading, intl} = this.props;
        const {showDeleteModal, user} = this.state;
        const usersTable = this.renderTable();
        return (
            <ElevatedBox className="users-list">
                <Loader loading={loading} inline />
                {usersTable}
                {showDeleteModal && <ConfirmationModal
                    title={intl.formatMessage(Messages.userList_deleteTItle)}
                    contentBold={intl.formatMessage(Messages.userList_deleteConfirmationBold, {name: user.displayName})}
                    contentReg={intl.formatMessage(Messages.userList_deleteConfirmationReg)}
                    primaryLabel={intl.formatMessage(Messages.general_delete)}
                    secondaryLabel={intl.formatMessage(Messages.general_cancel)}
                    onPrimaryClick={this.handleUserDelete}
                    onSecondaryClick={this.closeModal}
                />}
            </ElevatedBox>
        );
    }
}
