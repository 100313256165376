const convertMessages = (messagesObj) => {
    return Object.keys(messagesObj).reduce((final, key) => {
        final[key] = {id: key, defaultMessage: messagesObj[key]};
        return final;
    }, {});
}

const messages = {
    'general_add': 'Add',
    'general_cancel': 'Cancel',
    'general_delete': 'Delete',
    'general_close': 'Close',
    'general_save': 'Save',
    'general_true': 'Yes',
    'general_false': 'No',
    'general_submit': 'Submit',
    'general_filter': 'Filter',
    'general_yes': 'Yes',
    'general_no': 'No',
    'general_approve': 'Approve',
    'general_nextTime': 'Maybe next time',

    'clickToCopy_copy': 'Copy',
    'clickToCopy_copied': 'Copied',
    'clickToCopy_not_copied': 'ERROR: Not Copied',

    'loading': 'Loading...',
    'login_header': 'Login Form',
    'login_userLabel': 'Username',
    'login_passwordLabel': 'Password',
    'login_mismatch': 'Email and password do not match',
    'login_failed': 'Oops, an error occurred',
    'login': 'Log in',
    'login_joinUs': 'Don\'t have Linkatch yet?',
    'login_joinUsLink': 'To contact us and for more details',
    'access_unauthorizedToView': 'You do not have access to this page',

    'company_id': 'Company ID',
    'company_locale': 'Company locale',
    'company_name': 'Company name',
    'company_logo': 'Logo',
    'company_cover': 'Banner',
    'company_coverMobile': 'Banner for mobile',
    'company_website': 'Company website',
    'company_about': 'About us',
    'company_terms': 'Terms of use',
    'company_location': 'Company headquarters',
    'company_contactName': 'Contact\'s name (for internal use)',
    'company_contactPhone': 'Contact\'s phone number (for internal use)',
    'company_contactEmail': 'Contact\'s email address (for internal use)',
    'company_leadEmails': 'Emails for sending leads',
    'company_rIncentive': 'Incentive statement for employee referral',
    'company_aIncentive': 'Incentive statement for candidate',
    'company_referrerWorthTitle': 'Title',
    'company_referrerWorth': 'Why it\'s worth my while',
    'company_numEmployees': 'Number of employees',
    'company_cover_description': 'Drag banner here, or press to select (jpg, png, mp4)',
    'company_coverMobile_description': 'Drag banner here for mobile devices, or press to select (jpg, png, mp4)',
    'company_logo_description': 'Drag company logo here or press to select (jpg, png)',
    'company_newApplicantEmailTitle': 'Email message subject line for a new candidate',
    company_isCVPassThrough: 'CV Passthrough',

    'company_referrerMeta': 'Share link preview - employee referral',
    'company_applicantMeta': 'Share link preview - candidate',
    'company_referrerMetaTitle': 'Title',
    'company_referrerMetaDescription': 'Description',
    'company_referrerMetaImg': 'Image',
    'company_referrerMetaImg_description': 'Drag image here (jpg, png)',
    'company_applicantMetaTitle': 'Title',
    'company_applicantMetaDescription': 'Description',
    'company_applicantMetaImg': 'Image',
    'company_applicantMetaImg_description': 'Drag image here (jpg, png)',

    'company_preview_company': 'View jobs',
    'company_site_title': 'Our website',
    'job_links_title': 'Share job',

    'create_job_submit': 'Create job',
    create_job_submit_and_notify: 'Create job & Notify',
    'edit_job_submit': 'Save job',

    'job_id': 'Job ID',
    'job_shareUrlLink': 'Link for distribution to all the employees',
    'job_title': 'Job Title',
    'job_description': 'Job description',
    'job_internalId': 'Job Internal Code (internal ID, optional)',
    'job_image': 'Image',
    'job_requirements': 'Requirements',
    'job_type': 'Job type',
    'job_status': 'Job Type',
    'job_location': 'Location',
    'job_posted': 'Created',
    'job_salary': 'Salary',
    'job_leadEmails': 'Emails for sending leads',    
    'job_contactName': 'Contact\'s name (for internal use)',
    'job_contactPhone': 'Contact\'s phone number (for internal use)',
    'job_contactEmail': 'Contact\'s email address (for internal use)',
    'job_active': 'This job is active',
    'job_inactive': 'This job is inactive',
    'job_rIncentive': 'Incentive statement for employee referral',
    'job_aIncentive': 'Incentive statement for candidate',
    'job_referrerWorthTitle': 'Title',
    'job_referrerWorth': 'Why it\'s worth my while',
    'job_cover': 'Job banner',
    'job_coverMobile': 'Job banner for mobile',
    'job_order': 'Priority',
    'job_innerRecruiter': 'Recruiter',
    'job_innerRecruiterManager': 'Professional Manager',
    'job_section': 'Department',

    'job_innerFields_title': 'Fields for internal use',

    'job_recruiter_title': 'Let\'s Meet',
    'job_recruiterImg': 'Recruiter\'s photo',
    'job_recruiterName': 'Recruiter\'s name',
    'job_recruiterHeadline': 'Title',
    'job_recruiterDescription': 'Brief description',

    'job_referrerMeta': 'Share link preview - employee referral',
    'job_applicantMeta': 'Share link preview - candidate',
    'job_referrerMetaTitle': 'Title',
    'job_referrerMetaDescription': 'Description',
    'job_referrerMetaImg': 'Image',
    'job_referrerMetaImg_description': 'Drag image here (jpg, png)',
    'job_applicantMetaTitle': 'Title',
    'job_applicantMetaDescription': 'Description',
    'job_applicantMetaImg': 'Image',
    'job_applicantMetaImg_description': 'Drag image here (jpg, png)',

    'job_cover_description': 'Drag banner here that will replace the company banner for this job (jpg, png, mp4)',
    'job_coverMobile_description': 'Drag banner here that will replace the company banner on mobile devices for this job (jpg, png, mp4)',
    'job_notSpecified': 'Not specified',
    'job_preview_job': 'Preview',

    'job_status_full': 'Full-time job',
    'job_status_part': 'Part-time job',
    'job_status_shifts': 'Shiftwork',
    'job_status_freelance': 'Freelance',
    'job_status_relocation': 'Relocation',

    'jobList_header_order': 'Order',
    'jobList_header_title': 'Job Title',
    'jobList_header_active': 'Active job',
    'jobList_header_created': 'Date created',
    'jobList_header_appplicantCount': 'Candidates',
    'jobList_header_viewCount': 'Views',
    'jobList_header_shareCount': 'Shares',
    'jobList_header_actions': 'Actions',
    'jobList_header_innerRecruiter': 'Recruiter',
    'jobList_header_innerRecruiterManager': 'Recruitment manager',
    'jobList_header_section': 'Department',
    'jobList_header_internalId': 'Internal Code',

    'jobList_promoteLabel': 'Push',
    'jobList_pushTitle': 'Push job',
    'jobList_pushConfirmationBold': 'Are you sure that you want to request Push "{name}" Job?',
    'jobList_pushConfirmationReg': '{customerName} will push the job in the digital media for you',
    'jobList_pushLabel': 'PUSH!',
    jobList_sendNotification: 'Sent notification',
    
    'jobsFilter_job': 'Job Title',
    'jobsFilter_internalId': 'Job internal code',
    'jobsFilter_innerRecruiter': 'Recruiter',
    'jobsFilter_innerRecruiterManager': 'Recruitment manager',
    'jobsFilter_section': 'Department',

    'jobList_actions_duplicate': 'Replicate job',

    'jobs_fullInfo_title': 'For full details',
    'job_inactive_title': 'Sorry, this job is no longer active',

    'referrer_internalId': 'Employee no.',
    'referrer_name': 'Full name',
    'referrer_id': 'Phone',
    'referrer_phone': 'Phone',
    'referrer_email': 'Email',
    'referrer_section': 'Department',
    'referrer_birthday': 'Birthday',
    'referrer_comment': 'Field 1',
    'referrer_comment2': 'Field 2',
    'referrer_comment3': 'Field 3',
    'referrer_applicants': 'Candidates',
    'referrer_views': 'Views',
    'referrer_shares': 'Shares',
    'referrer_gdpr': 'Terms screens',
    'referrer_created': 'Date created',
    'referrer_lastActive': 'Recently active',

    'create_new_referrer': 'Create a new employee',
    'error_referrerAlreadyExists': 'An employee with the present telephone number already exists',

    //menu
    'menu_menuItem_companies': 'Companies',
    'menu_menuItem_referrers': 'Employees',
    'menu_menuItem_details': 'Company details',
    'menu_menuItem_newCompany': 'Add company',
    menu_menuItem_apiKeys: 'Api Keys',
    menu_menuItem_terms: 'Terms',
    menu_menuItem_notifications: 'Notifications',
    menu_menuItem_notifHistory: 'Notifications History',
    'menu_menuItem_users': 'User management',
    'menu_menuItem_userList': 'List of users',
    'menu_menuItem_newJob': 'New job',
    'menu_menuItem_applicants': 'Candidates',
    'menu_menuItem_dashboard': 'Dashboard',
    'menu_menuItem_companyJobs': 'Jobs',
    'menu_menuItem_newUser': 'New user',
    'menu_menuItem_allUsers': 'All users',
    'menu_menuItem_settings': 'Settings',
    'menu_menuItem_logout': 'Log out',
    'companyNotFound': 'Company not found.',

    'navigation_addNewJob': 'Add new job',

    'shareJobForm_title': 'Enter your details. a special link will be created for you to share with your friends',
    'shareJobForm_referrerId': 'Phone',
    'shareJobForm_name': 'Full name',
    'shareJobForm_gdpr': 'I agree to receiving messages under the {terms}',
    'shareJobForm_gdpr-link': 'terms',
    'shareJobForm_submitLabel': 'Create a unique link',

    'generatedLink_name': 'Hi {name} ',
    'generatedLink_linkMessage': 'Select how you want to share the job',
    'generatedLink_linkMessage_jobs': 'Share our jobs',

    'applicantsFilter_submit': 'Filter',
    'applicantsFilter_referrerId': 'Employee Phone',
    'applicantsFilter_date': 'Date',
    'applicantsFilter_companyId': 'Company',    
    'applicantsFilter_jobTitle': 'Job Title',
    'applicantsFilter_status': 'Status',
    
    'applicant_status_new': 'New',
    'applicant_status_irrelevant': 'Not applicable',
    'applicant_status_recruited': 'Hired',
    'applicant_status_inProgress': 'In process',

    'applicant_name': 'Full name',
    'applicant_referrerId': 'Employee referral',
    'applicant_companyName': 'Company',
    'applicant_jobId': 'Job no.',
    'applicant_status': 'Status',
    'applicant_email': 'Email',
    'applicant_phone': 'Phone',
    'applicant_created': 'Date',
    'applicant_cv': 'Resume',
    'applicant_cvFile': 'Resume file',
    'applicant_aboutMe': 'About me',
    'applicant_site': 'Link to my LinkedIn profile',
    'applicant_gdpr': 'Terms screens',

    'applicantList_notFound': 'No candidates yet :(',
    'applicantList_cvFile': 'File',

    'createApplicationForm_showMore': 'Additional options',
    'createApplicationForm_submit': 'Submit candidacy',
    'createApplicationForm_applied': 'Your application has been registered!',
    'createApplicationForm_goodLuck': 'Good luck!',
    'createApplicationForm_moreJobsTitle': 'Have you taken a look at our other jobs?',
    'createApplicationForm_moreJobsSubtitle': 'Maybe there\'s a job that would fit a friend of yours',
    'createApplicationForm_cv': 'Upload your resume (PDF/docx)',
    'createApplicationForm_gdpr': 'I agree to receiving messages under the {terms}',
    'createApplicationForm_gdpr-link': 'terms',

    'users_usersNotFound': 'You have still not created any users',
    userList_header_mfa: 'MFA',
    'userList_header_displayName': 'Name',
    'userList_header_email': 'Email',
    'userList_header_companyName': 'Company',
    'userList_deleteTItle': 'Delete user',
    'userList_deleteConfirmationBold': 'Are you sure you want to delete {name}?',
    'userList_deleteConfirmationReg': 'This user will no longer be able to log in to the system',

    'create_user_submit': 'Create user',
    'edit_user_submit': 'Update user',
    'createEditUser_label_displayName': 'Name',
    'createEditUser_label_email': 'Email',
    'createEditUser_label_companyId': 'Company',
    'createEditUser_label_password': 'Password',
    createEditUser_label_mfa: 'Require MFA',

    apiKeysList_header_name: 'Name',
    apiKeysList_header_enabled: 'Enabled',
    apiKeysList_header_companyName: 'Company',
    apiKeysList_deleteTitle: 'Delete Api Key',
    apiKeysList_deleteConfirmationBold: 'Are you sure you want to delete {name}?',
    apiKeysList_deleteConfirmationReg: 'This action cannot be undone',

    create_apiKey_submit: 'Create Api Key',
    edit_apiKey_submit: 'Update Api Key',
    createEditApiKey_label_name: 'Name',
    createEditApiKey_label_email: 'Email',
    createEditApiKey_label_enabled: 'Active',
    createEditApiKey_label_companyId: 'Company',
    createEditApiKey_label_id: 'Access Key',

    'actions_edit': 'Edit',
    'actions_cancel': 'Cancel',
    'actions_create': 'Create',

    'fileUpload_fileSelected': 'Selected file',
    'imageUpload_replace': 'Drag another image (or press) to replace image',
    'imageUpload_prefered': 'Recommended size: {preferedDimentions} (up to {maxSize})',

    'form_requiredField': 'Required field',
    'form_unsavedChanges': 'There are changes that you haven\'t saved Do you want to leave this page?',

    'contact_title': 'Contact us and Notifications',
    'incentives_title': 'Incentives',
    'settings_title': 'Settings',
    'referrer_explanation_title': 'Explanation for employee referral',
    'jobs_link_sub_cmp': 'Jobs page',
    'job_location_and_map_title': 'Job location',
    'company_location_and_map_title': 'Company location',
    'general_details_title': 'General details',

    'validation_errors_present': 'There are errors on the form',
    'validation_required': 'Required field',
    'validation_minLength': 'Minimum of {minLength} characters',
    'validation_exactLength': 'Must be {length} characters long',
    'validation_email': 'Invalid email address',
    'validation_identification_unavailable': 'This ID is already taken',
    'validation_identification_available': 'This ID is free',
    'validation_cv': 'Upload PDF and docx files only',
    'validation_invalid_media_file': 'Invalid file size/ type',

    'operation_success': 'Operation successful',
    'operation_failure': 'Operation failed',

    'dashboard_totalJobs': 'Jobs in the system',
    'dashboard_activeJobs': 'Active jobs',
    'dashboard_inactiveJobs': 'Inactive jobs',
    'dashboard_totalCompanies': 'Companies',
    'dashboard_referrers': 'Registered Employees',
    'dashboard_activeEmployees': '% Registered Employees',
    'dashboard_shares': 'Shares',

    'dashboard_column_title': 'Job Title',
    'dashboard_column_viewed': 'Views',
    'dashboard_column_applied': 'Candidates',
    'dashboard_column_shares': 'Shares',
    'dashboard_column_name': 'Name',
    'dashboard_column_email': 'Email',
    'dashboard_column_phone': 'Phone',

    'dashboard_title_totalViews': 'Views',
    'dashboard_title_totalApplicants': 'Candidates',

    'admin_exportCSV': 'Export to CSV',
    
    'referrerWorth_title': 'Why is it worth my while?',
    'return_to_alljobs': 'Check more jobs',

    // draftjs
    // Generic
    'generic.add': 'Add',
    'generic.cancel': 'Cancel',
    // BlockType
    'components.controls.blocktype.h3': 'Title',
    'components.controls.blocktype.normal': 'Regular',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // History
    'components.controls.history.history': 'History',
    'components.controls.history.undo': 'Cancel',
    'components.controls.history.redo': 'Back',
    // TextAlign
    'components.controls.textalign.textalign': 'Text alignment',
    'components.controls.textalign.left': 'Left align',
    'components.controls.textalign.center': 'Center',
    'components.controls.textalign.right': 'Right align',
    'components.controls.textalign.justify': 'Justified',
    // Inline
    'components.controls.inline.bold': 'Bold',
    'components.controls.inline.italic': 'Italics',
    'components.controls.inline.underline': 'Underline',
    'components.controls.inline.strikethrough': 'Strikethrough',
    'components.controls.inline.monospace': 'Even spacing',
    'components.controls.inline.superscript': 'Upper text',
    'components.controls.inline.subscript': 'Lower text',

    //react-dates
    'date_startDate': 'From date',
    'date_endDate': 'To date',

    'shareableLinks_fb': 'Facebook',
    'shareableLinks_ln': 'LinkedIn',
    'shareableLinks_wa': 'WhatsApp',
    'shareableLinks_url': 'Copy link',
    'shareableLinks_coppied': 'Copied',
    'shareableLinks_manualCopy': 'To copy, press CTRL+C',

    'footer_terms': 'Terms',
    'footer_rights': 'All rights reserved to PRO-MAN',
    'footer_developed': 'Development: SCOTTY',
    
    'cropModal_title': 'Crop image',
    'cropModal_leaveUncropped': 'Do not crop',
    'cropModal_crop': 'Crop image',
    'cropModal_explain': 'Drag the image to the desired location. Use wheel to zoom',
    'cropModal_notSupported': 'Video cutting is not supported',
    'cropModal_previewWeb': 'This is how the image will appear on a computer:',
    'cropModal_previewMobile': 'This is how the image will appear on a phone:',
    'cropModal_previews_title': 'Previews',

    'sharesList_header_referrer': 'Employee',
    'sharesList_header_date': 'Date',
    'sharesList_header_share': 'Type of share',
    'sharesList_header_job': 'Job shared',
    'sharesList_share_wa': 'WhatsApp',
    'sharesList_share_fb': 'Facebook',
    'sharesList_share_ln': 'LinkedIn',
    'sharesList_share_url': 'Copy link',
    'sharesList_jobListShare': '<Jobs page>',

    'settings_terms': 'Terms',

    'importCsv_title': 'Import employees from csv file',
    'importCsv_fileUploadField': 'Drag Excel/CSV file of employees here',
    'importCsv_fileUploadExample': 'Sample csv file',
    'importCsv_advanced_toggle': 'Advanced settings',
    'importCsv_status_success': 'Import from Date {date} was successful It may take several minutes before the changes are visible',
    'importCsv_status_inProgress': 'Import from Date {date} is in process',
    'importCsv_status_failure': 'Import from Date {date} failed.',
    'importCsv_importInProgress': 'Importing of employees has started. This may take several minutes...',
    'importCsv_failureReason_invalidPhone': '"Phone" field in line {line} is not valid. (Enter 10 digits)',
    'importCsv_failureReason_invalidEmail': 'The "Email" field in line {line} is not valid.',
    'importCsv_failureReason_invalidGdpr': 'The "I agree to the terms" field in line {line} is not valid. (Enter true/false/TRUE/FALSE)',
    'importCsv_failureReason_invalidBirthday': 'The "Birthday" field in line {line} is not valid. (Enter in the format 27/10/2001)',
    'importCsv_failureReason_contactAdmin': 'Contact your system administrator.',
    'importCsv_overrideWarning': 'Note: importing a file of employees deletes the current table of employees in the system.',
    'importCsv_ignoreFirstLine': 'Ignore first line',

    'referrersFilter_findReferrer': 'Find an employee',
    'referrersFilter_segmentReferrers': 'Filter employees for exporting',
    'referrersFilter_fieldChooser_title': 'Date field',

    'template_companyName': 'Company name',
    'template_jobInternalId': 'Job ID',
    'template_jobTitle': 'Job title',
    'template_referrerPhone': 'Employee Phone',
    'template_referrerName': 'Employee Name',
    'template_applicantName': 'Applicant Name',

    'locale_en': 'English',
    'locale_he': 'Hebrew',

    'not_supported': 'The management panel is not available in Internet Explorer Use a more advanced web browser',

    notifications_enabled: 'Notifications enabled',
    notifications_disabled: 'Notifications disabled',
    notifications_status_title: 'Notifications status',
    notifications_disabled_warning: 'Your employees do not get notified of new jobs and referrees! Want in? - Contact us!',
    notifications_quota_warning: 'It seems like your notification bank has depleted. Contact us to recharge!',
    
    notif_amountLeft: 'Amount left',
    notif_monthlyBank: 'Monthly pack',
    notif_monthlyBank_explain: 'Every 1st of the month, notification balance increases back to your Monthly Bank',
    notifications_templates_title: 'Notifications templates',
    notif_template_newJob_title: 'New job template',
    notif_template_newApplicant_title: 'New applicant template',
    notif_template_title: 'Notification title template',
    notif_template_body: 'Notificaiton body template',

    create_notif_title: 'Notification title',
    create_notif_body: 'Notification body',
    create_notif_img: 'Notification image',
    create_notif_approximate: 'Notification will be sent to approximately {number} employees',

    send_job_notification: 'Send job notification',
    notif_request_register_title: 'Get notified when someone you refer applies or new jobs are listed!',
    notif_revoke: 'Revoke notification',
    notif_revoked: 'Notifications revoked',
    notif_cantSendNotification: 'You don\'t have notifications. Want it? contact us',

    notifHistory_header_type: 'Type',
    notifHistory_header_title: 'Title',
    notifHistory_header_created: 'Created',
    notifHistory_header_received: 'Sent',
    notifHistory_header_opened: 'Opened',

    notif_type_newJob: 'New Job',
    notif_type_newApplicant: 'New Applicant',

    referrersList_disableRevokeNotifTitle: 'Revoke notification',
    referrersList_disableRevokeNotifBold: 'Are you sure you want to disable notifications for employee {name}?',
    referrersList_disableRevokeNotifReg: 'This action cannot be undone',
    referrersList_revokeLabel: 'Revoke Notification',

    previewRatio_fb: 'Facebook',
    previewRatio_wa: 'Whatsapp',

    mfa_scanQRCode: 'Please scan the QR code in {authenticator} and insert the code shown in the app',
    mfa_openAppAndInsertCode: 'Open {authenticator} and insert the presented code',
    mfa_codeLabel: 'Code in Authenticator',
    mfa_invalidCodeError: 'Invalid Code, please try again',
};

export default convertMessages(messages);
