import axios from 'services/axios';
import firebase from 'services/firebase';
import {put, call, all, fork, select, takeLatest} from 'redux-saga/effects';
import {ActionTypes as ApplicantActionTypes, Actions as ApplicantActions, Selectors as ApplicantSelectors} from 'modules/applicant';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Selectors as CompanyJobsSelectors} from 'modules/companyJobs';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as AuthSelectors} from 'modules/auth';
import config from 'config';
import { createCSV, prepareObjectToCsv } from 'utils/transformUtils';
import fileSaver from 'file-saver';
import { fetchJobById, fetchJobByIdLogic } from 'modules/companyJobs/companyJobsSagas';
import { fetchReferrerById, fetchReferrerByIdLogic } from 'modules/referrer/referrerSagas';

export function* submitApplicationForm(action) {
    yield put(ApplicantActions.SUBMIT_APPLICATION_FORM_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const jobId = yield select(CompanyJobsSelectors.matchingJobIdSelector);
        const referrerLink = yield select(ReferrerSelectors.matchingReferrerLinkSelector);
        
        const url = `companies/${companyId}/jobs/${jobId}/newApplication`;
        const payload = {applicant: action.payload, referrerLink}
        yield call(axios.post, url, payload);
        
        yield put(ApplicantActions.SUBMIT_APPLICATION_FORM_SUCCESS(jobId));
    } catch (err) {
        yield put(ApplicantActions.SUBMIT_APPLICATION_FORM_FAILURE(err));
    }
};

export function* editApplicant(action) {
    const {payload} = action;
    yield put(ApplicantActions.APPLICANTS_UPDATE_APPLICANT_REQUEST());
    try {
        const applicant = yield select(ApplicantSelectors.matchingApplicantSelector);
        
        yield call(axios.patch, `customers/me/applicants/${applicant.id}`, payload);
        
        yield put(ApplicantActions.APPLICANTS_UPDATE_APPLICANT_SUCCESS({...applicant, ...payload}));
    } catch (err) {
        yield put(ApplicantActions.APPLICANTS_UPDATE_APPLICANT_FAILURE(err));
    }
};

const fetchApplicantsLogic = async (filter, customerId, limitingCompanyId, fetchAll) => {
    let queryBuilder = firebase.firestore()
    .collection('customers')
    .doc(customerId)
    .collection('applicants')
    .orderBy('created', 'desc');

    if (!fetchAll) {
        queryBuilder = queryBuilder.limit(config.applicantsPagination);
    }

    const {referrerId, companyId, jobId, date, status, startAfter} = filter;
    const companyIdFilter = limitingCompanyId || companyId;
    if (referrerId) {
        queryBuilder = queryBuilder.where('referrerId', '==', referrerId);
    }
    if (companyIdFilter) {
        queryBuilder = queryBuilder.where('companyId', '==', companyIdFilter);
    }
    if (jobId) {
        queryBuilder = queryBuilder.where('jobId', '==', jobId);
    }
    const {startDate, endDate} = date || {};
    if (startDate) {
        queryBuilder = queryBuilder.where('created', '>=', startDate.toDate());
    }
    if (endDate) {
        queryBuilder = queryBuilder.where('created', '<=', endDate.toDate());
    }
    if (status) {
        queryBuilder = queryBuilder.where('status', '==', status);
    }
    if (startAfter) {
        queryBuilder = queryBuilder.startAfter(startAfter);
    }
    const applicantsQuery = await queryBuilder.get();
    const applicants = applicantsQuery.docs.map(d => ({...d.data(), id: d.id}));
    const last = applicantsQuery.docs[applicantsQuery.docs.length - 1];
    return {applicants, last};
}

function* fetchApplicantJobs(applicants) {
    const jobs = yield select(CompanyJobsSelectors.companyJobsSelector);
    const unique = applicants.filter((a) => {
        const firstApplicantWithJobId = applicants.find(ia => ia.jobId === a.jobId);
        return firstApplicantWithJobId === a && !jobs.find(j => j.id === a.jobId);
    });
    yield all(unique.map(applicant => fork(fetchJobById, applicant.companyId, applicant.jobId)));
}

function* fetchApplicantReferrers(applicants) {
    const referrers = yield select(ReferrerSelectors.referrersSelector);
    const unique = applicants.filter((a) => {
        const firstApplicantWithReferrerId = applicants.find(ia => ia.referrerId === a.referrerId);
        return firstApplicantWithReferrerId === a && a.referrerId && !referrers.find(j => j.id === a.referrerId);
    });
    yield all(unique.map(applicant => fork(fetchReferrerById, applicant.referrerId, applicant.companyId)));
}

function* fetchApplicants() {
    yield put(ApplicantActions.FETCH_APPLICANTS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const limitingCompanyId = yield select(AuthSelectors.limitedToCompanySelector);
        const filter = yield select(ApplicantSelectors.applicantsFilterSelector);

        const {applicants, last} = yield fetchApplicantsLogic(filter, customerId, limitingCompanyId);
        yield fork(fetchApplicantJobs, applicants);
        yield fork(fetchApplicantReferrers, applicants);

        yield put(ApplicantActions.FETCH_APPLICANTS_SUCCESS({applicants, last}));
    } catch (err) {
        console.log(err);
        yield put(ApplicantActions.FETCH_APPLICANTS_FAILURE(err));
    }
}

function* fetchMoreApplicants() {
    yield put(ApplicantActions.FETCH_MORE_APPLICANTS_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const limitingCompanyId = yield select(AuthSelectors.limitedToCompanySelector);
        const filter = yield select(ApplicantSelectors.applicantsFilterSelector);
        const lastSnap = yield select(ApplicantSelectors.lastApplicantSelector);
        const paginatedFilter = lastSnap ? {...filter, startAfter: lastSnap} : filter;
        const {applicants, last} = yield fetchApplicantsLogic(paginatedFilter, customerId, limitingCompanyId);
        yield fork(fetchApplicantJobs, applicants);

        yield put(ApplicantActions.FETCH_MORE_APPLICANTS_SUCCESS({applicants, last}));
    } catch (err) {
        console.log(err);
        yield put(ApplicantActions.FETCH_MORE_APPLICANTS_FAILURE(err));
    }
}
const addJobData = async (applicants) => {
    const applicantsWithUniqueJobs = applicants.filter((a) => {
        const firstApplicantWithJobId = applicants.find(ia => ia.jobId === a.jobId);
        return firstApplicantWithJobId === a;
    });
    const applicantsWithUniqueReferrers = applicants.filter((a) => {
        if (!a.referrerId) {
            return false;
        }
        const firstApplicantWithReferrerId = applicants.find(ia => ia.referrerId === a.referrerId);
        return firstApplicantWithReferrerId === a;
    });
    const jobsPromises = applicantsWithUniqueJobs.map(a => fetchJobByIdLogic(a.jobId));
    const referrersPromises = applicantsWithUniqueReferrers.map(a => fetchReferrerByIdLogic(a.referrerId, a.companyId));
    const [jobs, referrers] = await Promise.all([
        Promise.all(jobsPromises),
        Promise.all(referrersPromises),
    ]);
    const jobById = jobs.reduce((aggr, job) => {
        aggr[job.id] = job;
        return aggr;
    }, {});
    const referrerById = referrers.reduce((aggr, referrer) => {
        aggr[referrer.id] = referrer;
        return aggr;
    }, {});
    const mappedApplicants = applicants.map(a => ({
        ...a,
        job: jobById[a.jobId],
        referrer: referrerById[a.referrerId],
    })).map(prepareObjectToCsv);
    return mappedApplicants;
}

export function* generateApplicantsReport(action) {
    yield put(ApplicantActions.GENERATE_APPLICANTS_REPORT_REQUEST());
    try {
        const customerId = yield select(AuthSelectors.customerIdSelector);
        const limitingCompanyId = yield select(AuthSelectors.limitedToCompanySelector);
        const filter = yield select(ApplicantSelectors.applicantsFilterSelector);
        const {applicants} = yield fetchApplicantsLogic(filter, customerId, limitingCompanyId, true);
        const applicantsWithJobs = yield addJobData(applicants);
        const columns = {
            companyId: 'Company Id',
            jobId: 'Job Id',
            'job.title': 'Job Title',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            'referrer.name': 'Referrer name',
            referrerId: 'Referrer phone',
            status: 'status',
            createdFormatted: 'date',
            cv: 'CV',
            site: 'LinkedIn',
            aboutMe: 'About Me',
        };
        const csv = yield createCSV(applicantsWithJobs, {columns});
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
        yield put(ApplicantActions.GENERATE_APPLICANTS_REPORT_SUCCESS());
        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `applicants-${currentDate}.csv`);
    } catch (err) {
        console.error(err);
        yield put(ApplicantActions.GENERATE_APPLICANTS_REPORT_FAILURE(err));
    }
}

export default [
    takeLatest(ApplicantActionTypes.SUBMIT_APPLICATION_FORM_ACTION, submitApplicationForm),
    takeLatest(ApplicantActionTypes.APPLICANTS_UPDATE_APPLICANT, editApplicant),
    takeLatest([ApplicantActionTypes.FETCH_APPLICANTS_ACTION, ApplicantActionTypes.FILTER_APPLICANTS_RESULTS], fetchApplicants),
    takeLatest([ApplicantActionTypes.FETCH_MORE_APPLICANTS_ACTION], fetchMoreApplicants),
    takeLatest(ApplicantActionTypes.GENERATE_APPLICANTS_REPORT_ACTION, generateApplicantsReport),
];
